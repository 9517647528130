import React, { useEffect } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useTitleBarInput from '../../../../hooks/useTitleBarInput';
import useFetchData from '../../../../hooks/useFetchData';
import MarketOverviewTable from './subComponents/MarketOverviewTable.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useDispatch } from 'react-redux';
import { addAdditionalTitleBarComponents } from '../../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';

const MarketOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const dispatch = useDispatch();

  const component = {
    displayOnOverviewPage: true,
    titleBarKey: 'selectMarketOverviewType',
    titleBarTitle: 'Type: ',
    values: [
      {
        label: 'Volatility',
        value: 'vol',
      },
      {
        label: 'VaR',
        value: 'var',
      },
    ],
    aValueIsAlwaysSelected: true,
    currentValue: 'vol',
  };

  useEffect(() => {
    dispatch(
      addAdditionalTitleBarComponents(
        [component],
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );
  }, []);

  const type = useTitleBarSelect('selectMarketOverviewType') || 'vol';

  const lookbackDays = useTitleBarInput('marketOverviewLookbackDays');

  const marketOverviewData = useFetchData({
    url: `/market_overview/${positionDate}/${lookbackDays}`,
    keyName: `market_overview_${positionDate}_${lookbackDays}`,
    makeFetch: lookbackDays !== undefined,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[marketOverviewData]}
      customLoadingMessages={['Loading Market Overview Data...']}
      customErrorMessage="No Data Available."
    >
      <MarketOverviewTable data={marketOverviewData} type={type} />
    </GeneralComponentErrorShield>
  );
};

export default MarketOverview;
