import { FC, ReactElement } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { PEPageLinkButton } from '../peOverview/PeOverview.component';
import { Typography } from '@mui/material';

interface PeLiquidityTablerops {
  data: any;
  numDays: number | null;
  marketMove: number | null;
}

interface PeLiquidityStressTestsTableProps {
  data: any;
  numDays: number | null;
  marketMove: number | null;
}

function buildPeLiquidityStressTestsTableData(
  data: any,
  numDays: number | null,
  marketMove: number | null
): IPeLiquidityStressTestsTableData[] {
  if (!numDays || numDays === 0) return [];
  if (!marketMove || numDays === 0) return [];
  if (!data.data.length) return [];
  const timeSeriesData = data.data[0][numDays][marketMove];
  const tableData: IPeLiquidityStressTestsTableData[] = [];

  let key: keyof typeof timeSeriesData;
  for (key in timeSeriesData) {
    tableData.push({
      assetValue: timeSeriesData[key]['Asset Value'],
      assetDebtRatio: timeSeriesData[key]['Asset/Debt Ratio'],
      debtValue: timeSeriesData[key]['Debt Value'],
      discountedAdjustedAssetMovement:
        timeSeriesData[key]['Discounted Adjusted Asset Movement'] * -1,
      status: timeSeriesData[key]['status'],
      month: parseInt(key),
    });
  }

  return tableData;
}

interface IPeLiquidityStressTestsTableData {
  assetValue: number;
  assetDebtRatio: number;
  debtValue: number;
  discountedAdjustedAssetMovement: number;
  status: string;
  month: number;
}

const liquidityStressTestsColumns: CustomColumn<IPeLiquidityStressTestsTableData>[] =
  [
    {
      title: 'Month',
      field: 'month',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Discounted Adjusted Market Movement',
      field: 'discountedAdjustedAssetMovement',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        roundNumberToTwoDecimalPlaces(rowData.discountedAdjustedAssetMovement),
    },
    {
      title: 'Asset Value',
      field: 'assetValue',
      // render: (rowData: IAssetDebtRatiorData) => (
      //     <PEPageLinkButton
      //         value={specifyStatus(rowData.status)}
      //     />
      // ),
      render: (rowData: IPeLiquidityStressTestsTableData) =>
        addCommasToNumbersAndRound(rowData.assetValue),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Debt Value',
      field: 'debtvalue',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.debtValue),
    },
    {
      title: 'Asset/Debt Ratio',
      field: 'assetDebtRatio',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        roundNumberToTwoDecimalPlaces(rowData.assetDebtRatio),
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: IPeLiquidityStressTestsTableData) => (
        <PEPageLinkButton status={specifyStatus(rowData.status)} />
      ),
    },
  ];

function AssetDebtRatioTable({
  data,
  numDays,
  marketMove,
}: PeLiquidityStressTestsTableProps): ReactElement | null {
  const tableData = buildPeLiquidityStressTestsTableData(
    data,
    numDays,
    marketMove
  );
  // const columns = createFundAssetColumns(stressLevel as StressLevel);
  // const fundsSelector = createSectionFundsSelector('liquidity');
  // const funds = useSelector(fundsSelector);
  // const fundTitle =
  //     funds && funds.length
  //         ? funds.find((fund: any) => fund.id === activeFund)?.name + ' - Breakdown'
  //         : '';
  return (
    <GridItem xs={5} card cardStyle={{ minHeight: 400 }}>
      <CustomTable<IPeLiquidityStressTestsTableData>
        showToolbar={false}
        // loading={fundAssetData.isFetching}
        // csvFields={csvFundAssetLiquidationTime}
        options={{
          paging: false,
          sorting: false,
        }}
        data={tableData}
        columns={liquidityStressTestsColumns}
        // toolbarComponents={{
        //     toolbarTitle: fundTitle,
        // }}
      />
    </GridItem>
  );
}

const PeLiquidityTable: FC<PeLiquidityTablerops> = (props) => {
  const { data, numDays, marketMove } = props;
  const tableData = buildPeLiquidityStressTestsTableData(
    data,
    numDays,
    marketMove
  );
  return (
    <GridItem xs={12} card cardStyle={{ minHeight: 300 }}>
      <Typography
        variant="h2"
        style={{
          fontWeight: 300,
          textAlign: 'left',
          marginLeft: '2rem',
          marginTop: '2rem',
        }}
      >
        Timeseries Breakdown
      </Typography>
      <Typography
        variant="h3"
        style={{
          fontWeight: 300,
          textAlign: 'left',
          marginLeft: '2rem',
          marginTop: '0.5rem',
        }}
      >
        {`Number of Months: ${numDays === 0 ? '' : numDays}`}
      </Typography>
      <Typography
        variant="h3"
        style={{
          fontWeight: 300,
          textAlign: 'left',
          marginLeft: '2rem',
          marginTop: '0.5rem',
        }}
      >
        {`Market Movement: ${
          marketMove === 0
            ? ''
            : percentageToTwoDecimalPlaces(marketMove ? marketMove * -1 : 0)
        }`}
      </Typography>
      <CustomTable<IPeLiquidityStressTestsTableData>
        showToolbar={false}
        // loading={fundAssetData.isFetching}
        // csvFields={csvFundAssetLiquidationTime}
        options={{
          paging: false,
          sorting: false,
        }}
        data={tableData}
        columns={liquidityStressTestsColumns}
        emptyDataSourceMessage="Please Click on a Tile Above."
        // toolbarComponents={{
        //     toolbarTitle: fundTitle,
        // }}
      />
    </GridItem>
  );
};

export default PeLiquidityTable;
