import React, { FC, useEffect } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import PositionTable from './table/table.component';
import ExposureGraphics from './exposureGraphics/exposureGraphics.component';
import CreditRatingsTable from './creditRatings/creditRatings.component';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import AggregateFixedIncomeTable from './aggTable/aggTable.component';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import useActivefund from '../../../../../hooks/useActiveFund';
import { useDispatch } from 'react-redux';
import { clearPdfExports } from '../../../../../redux/pdfExport/actions';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';

export interface FixedIncomeComponent {
  inputData: DataObject;
}

export const FIXED_INCOME_OPTIONS = [
  'Position Table',
  'Exposure Graphics',
  'Credit Ratings Table',
  'Bubble Chart',
];

export type FixedIncomePages =
  | 'positionTable'
  | 'exposureGraphics'
  | 'creditRatingsTable';

export enum FixedIncomeOption {
  PC_OF_NAV = 'Percentage of NAV',
  BASE_CURRENCY = 'Base Currency',
}

export const fiOptions = [
  FixedIncomeOption.PC_OF_NAV,
  FixedIncomeOption.BASE_CURRENCY,
];

const handleComponent = (selectedPage: string | null) => {
  switch (selectedPage) {
    case 'positionTable':
      return PositionTable;
    case 'exposureGraphics':
      return ExposureGraphics;
    case 'creditRatingsTable':
      return CreditRatingsTable;
    default:
      return PositionTable;
  }
};
const MancoFixedIncome: FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  // const activeUcitsFund = fundId;

  const selectedPage = useTitleBarSelect('selectVisualisation');
  const selectedOutput = useTitleBarSelect('dataOutput');

  const SelectedComponent = handleComponent(selectedPage);
  const activeFund = useActivefund();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPdfExports());
  }, [selectedPage, selectedOutput]);

  const suffix =
    selectedOutput === 'bonds'
      ? 'bond_only_specific_data'
      : 'bond_specific_data';
  const datePrefix = !positionDate
    ? 'most_recently_stored_report_data_agg'
    : 'stored_report_data_agg';
  // const url = !positionDate
  //   ? `${datePrefix}/${activeUcitsFund}/${suffix}`
  //   : `${datePrefix}/${activeUcitsFund}/${suffix}/${positionDate}`;

  const fixedIncomeData = useFetchData({
    keyName: `manco_asset_class_fixed_income_data_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: `manco_level_asset_class_data/fixed_income${
      positionDate ? '/' + positionDate : ''
    }`,
    makeFetch: true,
  });
  return (
    <GeneralComponentErrorShield
      dataObjects={[fixedIncomeData]}
      customErrorMessage={'No data found for ' + activeFund?.name}
    >
      <AggregateFixedIncomeTable
        currentPage={(selectedPage as FixedIncomePages) || 'positionTable'}
        inputData={fixedIncomeData}
      />
      <SelectedComponent inputData={fixedIncomeData} />
    </GeneralComponentErrorShield>
  );
};

export default MancoFixedIncome;
