import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  createSectionByIdSelector,
  createSpecificFundNameAndIdSelectorByFundType,
} from '../../../../redux/pages/selectors';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralFundInfoPageProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  ShareClassHedgeTableData,
  generateShareClassHedgeData,
  generateShareClassTableColumns,
  getNavDate,
} from './ShareClassHedgeData.data';
import { useFilterFunds } from './ShareClassHedgeData.overview';

const ShareClassTable = ({
  data,
  title,
}: {
  data: DataObject;
  title: string;
}) => {
  const generatedData = generateShareClassHedgeData(data.data[0]);
  const navDate = getNavDate(data);
  return (
    <GridItem xs={12} card>
      <CustomTable<ShareClassHedgeTableData>
        title={title + ' - NAV Date: ' + navDate}
        data={generatedData}
        columns={generateShareClassTableColumns(
          data.data[0].base_currency || '-'
        )}
        options={{ paging: false, exportButton: true }}
        showToolbar
      />
    </GridItem>
  );
};

function ShareClassHedgeData({
  fundId,
  section,
}: GeneralFundInfoPageProps): ReactElement {
  const navStress = useTitleBarSelect('navStress');
  const fxStress = useTitleBarSelect('fxStress');

  const sectionDetailsSelector = createSectionByIdSelector(section);
  const sectionDetails = useSelector(sectionDetailsSelector);
  const fundDetailsSelector = createSpecificFundNameAndIdSelectorByFundType(
    sectionDetails?.fundTypes || 'UCITS',
    fundId,
    sectionDetails?.rsResources || 'RISK,LIQUIDITY'
  );
  const fundDetails = useSelector(fundDetailsSelector);
  const keyForFetch = `msc_share_class_fx_hedge_monitor_rt_agg/${fundId}/${navStress}/${fxStress}`;
  const shareClassData = useFetchData({
    url: keyForFetch,
    keyName: keyForFetch,
    makeFetch: true,
  });
  const dispatch = useDispatch();
  // this filtering is for coutts - can adapt it based on different clients
  useFilterFunds(
    [
      'coutts305',
      'coutts306',
      'coutts307',
      'bflexion101',
      'nanjia_001fx',
      'nanjia_002fx',
      'nanjia_003fx',
    ],
    sectionDetails?.fundTypes as string,
    sectionDetails?.rsResources as string
  );
  return (
    <GeneralComponentErrorShield dataObjects={[shareClassData]}>
      <ShareClassTable title={fundDetails?.name || ''} data={shareClassData} />
    </GeneralComponentErrorShield>
  );
}

export default ShareClassHedgeData;
