import React, { ReactElement } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import makeStyles from '@mui/styles/makeStyles';
import RaptorMultiSelect from '../../../selects/RaptorMultiSelect.component';
import RaptorDatePicker from '../../../selects/RaptorDatePicker.component';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createSectionFundsSelector,
} from '../../../../redux/pages/selectors';
import { Button, Switch, Typography } from '@mui/material';
import { mainColors } from '../../../../styling/theme';
import { BASE_URL } from '../../../../utilities/requestClient';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { getAllRelevantWeekdays } from '../../../../utilities/dateUtilities';
// import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginLeft: '2rem',
    marginTop: '1rem',
    marginBottom: '2rem',
    flexDirection: 'column',
  },
  dataPointSelect: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  positionDatesContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    width: '30%',
    justifyContent: 'space-around',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginBottom: '1rem',
  },
}));

// const historicalDataKeys: any[] = [
//     { value: 'net_asset_value', label: 'Net Asset Value' },
//     { value: 'portfolio_var', label: 'Portfolio VAR' },
//     { value: 'absolute_portfolio_var', label: 'Absolute Portfolio VAR' },
//     { value: 'relative_portfolio_var', label: 'Relative Portfolio VAR' },
//     { value: 'absolute_benchmark_var', label: 'Absolute Benchmark VAR' },
//     { value: 'portfolio_gross_exposure', label: 'Portfolio Gross Exposure' },
//     { value: 'portfolio_cash_gross_exposure', label: 'Portfolio Cash Gross Exposure' },
//     { value: 'portfolio_derivative_gross_exposure', label: 'Portfolio Derivative Gross Exposure' },
//     { value: 'portfolio_net_exposure', label: 'Portfolio Net Exposure' },
//     { value: 'portfolio_commitment', label: 'Portfolio Commitment' },
//     { value: 'portfolio_cash_commitment', label: 'Portfolio Cash Commitment' },
//     { value: 'portfolio_derivative_commitment', label: 'Portfolio Derivative Commitment' },
//     { value: 'portfolio_sum_of_notionals', label: 'Portfolio Sum of Notionals' },
//     { value: 'portfolio_asset_class_equity_commitment', label: 'Portfolio Asset Class Equity Commitment' },
//     { value: 'portfolio_asset_class_equity_gross_exposure', label: 'Portfolio Asset Class Equity Gross Exposure' },
//     { value: 'portfolio_asset_class_equity_net_exposure', label: 'Portfolio Asset Class Equity Net Exposure' },
//     { value: 'portfolio_asset_class_equity_derivative_gross_exposure', label: 'Portfolio Asset Class Equity Derivative Gross Exposure' },
//     { value: 'portfolio_asset_class_commodity_commitment', label: 'Portfolio Asset Class Commodity Commitment' },
//     { value: 'portfolio_asset_class_commodity_gross_exposure', label: 'Portfolio Asset Class Commodity Gross Exposure' },
//     { value: 'portfolio_asset_class_commodity_net_exposure', label: 'Portfolio Asset Class Commodity Net Exposure' },
//     { value: 'portfolio_asset_class_foreign_exchange_commitment', label: 'Portfolio Asset Class Foreign Exchange Commitment' },
//     { value: 'portfolio_asset_class_foreign_exchange_gross_exposure', label: 'Portfolio Asset Class Foreign Exchange Gross Exposure' },
//     { value: 'portfolio_asset_class_foreign_exchange_net_exposure', label: 'Portfolio Asset Class Foreign Exchange Net Exposure' },
//     { value: 'portfolio_asset_class_foreign_exchange_derivative_gross_exposure', label: 'Portfolio Asset Class Foreign Exchange Derivative Gross Exposure' },
//     { value: 'portfolio_asset_class_fixed_income_commitment', label: 'Portfolio Asset Class Fixed Income Commitment' },
//     { value: 'portfolio_asset_class_fixed_income_gross_exposure', label: 'Portfolio Asset Class Fixed Income Gross Exposure' },
//     { value: 'portfolio_asset_class_fixed_income_net_exposure', label: 'Portfolio Asset Class Fixed Income Net Exposure' },
//     { value: 'portfolio_asset_class_fixed_income_derivative_gross_exposure', label: 'Portfolio Asset Class Fixed Income Derivative Gross Exposure' },
//     { value: 'portfolio_bund_exposure', label: 'Portfolio Bund Exposure' },
//     { value: 'portfolio_asset_class_cash_commitment', label: 'Portfolio Asset Class Cash Commitment' },
//     { value: 'portfolio_asset_class_cash_gross_exposure', label: 'Portfolio Asset Class Cash Gross Exposure' },
//     { value: 'portfolio_asset_class_cash_net_exposure', label: 'Portfolio Asset Class Cash Net Exposure' },
//     { value: 'portfolio_asset_class_alternative_commitment', label: 'Portfolio Asset Class Alternative Commitment' },
//     { value: 'portfolio_asset_class_alternative_gross_exposure', label: 'Portfolio Asset Class Alternative Gross Exposure' },
//     { value: 'portfolio_asset_class_alternative_net_exposure', label: 'Portfolio Asset Class Alternative Net Exposure' },
//     { value: 'risk_compliance_1', label: 'Risk Compliance 1' },
//     { value: 'benchmark_var', label: 'Benchmark VAR' },
// ];

interface FileExportProps {
  availableDates: string[];
  riskMetrics: DataObject;
}

function formatRiskMetrics(riskMetrics: DataObject): any[] {
  if (!riskMetrics) return [];
  if (!('data' in riskMetrics)) return [];
  if (!riskMetrics.data) return [];
  if (!riskMetrics.data.length) return [];
  const metricsData = riskMetrics.data[0];
  const keys = Object.keys(metricsData);
  // sort the keys alphabetically
  keys.sort((a, b) =>
    metricsData[a].raptor_name.localeCompare(metricsData[b].raptor_name)
  );
  return keys.map((key) => ({
    value: key,
    label: metricsData[key].raptor_name,
  }));
}

export default function FileExport({
  availableDates,
  riskMetrics,
}: FileExportProps): ReactElement {
  const classes = useStyles();

  const historicalDataKeys = formatRiskMetrics(riskMetrics);

  // Create an array for storing the selected data points
  const [selectedDataPoints, setSelectedDataPoints] = React.useState<any[]>([]);
  // Create an object for storing start date
  const weekdays = getAllRelevantWeekdays();
  const [startDate, setStartDate] = React.useState<string>(
    availableDates.length > 1 ? availableDates[1] : weekdays[1]
  );
  // Object for storing end date
  const [endDate, setEndDate] = React.useState<string>(
    availableDates.length ? availableDates[0] : weekdays[0]
  );

  // Get the available funds
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits')
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];
  // Combine the fund ids and names into an array of objects
  const funds = fundIds.map((id, index) => ({
    value: id,
    label: fundNames[index],
  }));

  const [selectedFunds, setSelectedFunds] = React.useState<any[]>([]);

  // Object for indicating if one data point per sheet is selected (false means that oine fund per sheet is selected)
  const [oneDataPointPerSheet, setOneDataPointPerSheet] =
    React.useState<boolean>(false);

  function exportData() {
    // Create a form object that will be used for posting the request
    const mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST';
    // On form submit send the request to the correct endpoint
    mapForm.action = BASE_URL + 'generate_historical_data_file';
    // add the fund names
    const fund_names = selectedFunds.map((fund) => fund.value);
    const fundNamesInput = document.createElement('input');
    fundNamesInput.type = 'text';
    fundNamesInput.name = 'fund_names';
    fundNamesInput.value = JSON.stringify(fund_names);
    // Add to the form
    mapForm.appendChild(fundNamesInput);

    // add the data points
    const data_points = selectedDataPoints.map(
      (data_point) => data_point.value
    );
    const dataPointsInput = document.createElement('input');
    dataPointsInput.type = 'text';
    dataPointsInput.name = 'key_list';
    dataPointsInput.value = JSON.stringify(data_points);
    // Add to the form
    mapForm.appendChild(dataPointsInput);

    // add the start date
    const startDateInput = document.createElement('input');
    startDateInput.type = 'text';
    startDateInput.name = 'start_date';
    startDateInput.value = startDate;
    // Add to the form
    mapForm.appendChild(startDateInput);

    // add the end date
    const endDateInput = document.createElement('input');
    endDateInput.type = 'text';
    endDateInput.name = 'end_date';
    endDateInput.value = endDate;
    // Add to the form
    mapForm.appendChild(endDateInput);

    // add the one data point per sheet
    const outputFormatInput = document.createElement('input');
    outputFormatInput.type = 'text';
    outputFormatInput.name = 'output_format';
    outputFormatInput.value = oneDataPointPerSheet ? 'metric' : 'fund';
    // Add to the form
    mapForm.appendChild(outputFormatInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  return (
    <>
      <GridItem xs={12} card style={{ padding: '1rem' }}>
        <div className={classes.container}>
          <Typography variant="h6">
            What data points do you want to export?
          </Typography>
          <div className={classes.dataPointSelect}>
            <RaptorMultiSelect
              items={historicalDataKeys}
              label="Data Points"
              placeholder="Data Points"
              selectAllLabel="Select all..."
              onChange={setSelectedDataPoints}
              noOptionsText="No options"
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} card style={{ padding: '1rem' }}>
        <div className={classes.container}>
          <Typography variant="h6">For what Time Period?</Typography>
          <div className={classes.positionDatesContainer}>
            <RaptorDatePicker
              title={'Start Date'}
              handler={(val) => {
                setStartDate(dayjs(val).format('YYYY-MM-DD'));
              }}
              datesToInclude={availableDates}
              selectedDate={dayjs(startDate)}
              tiedToActiveDate={false}
            />
            <RaptorDatePicker
              title={'End Date'}
              handler={(val) => {
                setEndDate(dayjs(val).format('YYYY-MM-DD'));
              }}
              datesToInclude={availableDates}
              selectedDate={dayjs(endDate)}
              tiedToActiveDate={false}
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} card style={{ padding: '1rem' }}>
        <div className={classes.container}>
          <Typography variant="h6">
            What funds do you want to export data for?
          </Typography>
          <div className={classes.dataPointSelect}>
            <RaptorMultiSelect
              items={funds}
              // getOptionDisabled={getOptionDisabled}
              label="Funds"
              placeholder="Funds"
              selectAllLabel="Select all..."
              onChange={setSelectedFunds}
              noOptionsText="No options"
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} card style={{ padding: '1rem' }}>
        <div className={classes.container}>
          <Typography variant="h6">
            How would you like to split the data?
          </Typography>
          <div className={classes.dataPointSelect}>
            <div className={classes.switch}>
              <Typography>One fund per sheet</Typography>
              <Switch
                checked={oneDataPointPerSheet}
                onChange={() => setOneDataPointPerSheet(!oneDataPointPerSheet)}
              />
              <Typography>One data point per sheet</Typography>
            </div>
            <Button
              style={{
                backgroundColor: mainColors.controlButtonBlue,
                color: 'white',
              }}
              onClick={exportData}
            >
              Export
            </Button>
          </div>
        </div>
      </GridItem>
    </>
  );
}
