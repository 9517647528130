import React from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  OverviewTableData,
  createMainTableData,
  useOverviewColumns,
} from './data';
import Detail from './detail/component';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

export const riaifOverviewCsvFields = [
  'index',
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'commitmentStatus',
  'valueAtRiskStatus',
  'riaifLaw',
  'prospectusStatus',
];

const RiaifOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const clientName = useSelector(clientNameSelector);

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const reportsList = useFetchData({
    url: `reports_list` + schemas,
    keyName: `${fundTypes}_overview_reports_list`,
    makeFetch: true,
  });
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });

  // fetch in data
  const columns: CustomColumn<OverviewTableData>[] = useOverviewColumns(
    clientName,
    undefined
  );
  let renderData: any = [];
  if (overviewData && overviewData.data.length) {
    renderData = overviewData.data[0];
  }

  const renderReportsList =
    reportsList && reportsList.data.length ? reportsList.data : null;

  const dataPreparedForTable = createMainTableData(
    renderData,
    renderReportsList
  );

  // USE THIS FOR changing heatmap to button -> https://www.gitmemory.com/issue/mbrn/material-table/1340/555845261
  const tableHeight = 'calc(100vh - 25rem)';

  return overviewData ? (
    <GeneralComponentErrorShield dataObjects={[overviewData, reportsList]}>
      <GridItem card xs={12}>
        <CustomTable<OverviewTableData>
          csvFields={riaifOverviewCsvFields}
          showToolbar
          loadingHeight={tableHeight}
          title={'RIAIF Overview Data'}
          columns={columns}
          data={dataPreparedForTable}
          detailPanel={[
            {
              disabled: false,

              render: (rowData: OverviewTableData) => {
                const newDict: any = {};
                rowData.countryExposure.forEach((item: any[]) => {
                  newDict[item[0]] = item[1];
                });
                let maxVal = 0;
                rowData.countryExposure.forEach((arr: any) => {
                  if (arr[1] > maxVal) {
                    maxVal = arr[1];
                  }
                });
                return (
                  <Detail
                    varFund={rowData.varFund === 'yes'}
                    mapData={newDict}
                    passValue={mapStatusToColor(
                      specifyStatus(rowData.timeSeriesStatus)
                    )}
                    val={maxVal}
                    barChartInputData={rowData.positionExposure}
                    timeSeriesData={
                      rowData.timeSeriesData ? rowData.timeSeriesData : []
                    }
                    fundId={rowData.internalFundName}
                  />
                );
              },
            },
          ]}
          options={{
            paging: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, dataPreparedForTable.length],
            search: true,
            exportButton: true,
            rowStyle: {
              padding: '1rem',
            },
            raptorExport: true,
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  ) : null;
};

export default RiaifOverview;
