import React from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  OverviewTableData,
  createMainTableData,
  useOverviewColumnsDefault,
} from './data';
import OverviewSecondLevelGraphs from '../../sharedComponents/overview/OverviewSecondLevelGraphs.component';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

export const ucitsOverviewCsvFields = [
  'index',
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'commitmentStatus',
  'valueAtRiskStatus',
  'ucitsLaw',
  'prospectusStatus',
];
export const adepaUcitsOverviewCsvFields = [
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'commitmentStatus',
  'valueAtRiskStatus',
  'ucitsLaw',
  'prospectusStatus',
];

const UcitsOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const clientName = useSelector(clientNameSelector);

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const reportsList = useFetchData({
    url: `reports_list` + schemas,
    keyName: `${fundTypes}_overview_reports_list`,
    makeFetch: true,
  });
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });
  const subFundRiskReportsList = useFetchData({
    url: 'reports_list/sub_fund_risk_report' + schemas,
    keyName: `${fundTypes}_sub_fund_risk_report`,
    makeFetch: clientName?.includes('pure') || false,
  });

  const columns: CustomColumn<OverviewTableData>[] = useOverviewColumnsDefault(
    clientName,
    undefined
  );
  
  let renderData: any = [];
  if (overviewData && overviewData.data.length) {
    renderData = overviewData.data[0];
  }

  const renderReportsList =
    reportsList && reportsList.data.length ? reportsList.data : null;

  const renderSubFundRiskReportsList =
    subFundRiskReportsList && subFundRiskReportsList.data.length
      ? subFundRiskReportsList.data
      : null;

  const dataPreparedForTable = createMainTableData(
    renderData,
    renderReportsList,
    renderSubFundRiskReportsList,
    clientName,
    'ucits'
  );
  // USE THIS FOR changing heatmap to button -> https://www.gitmemory.com/issue/mbrn/material-table/1340/555845261
  const tableHeight = 'calc(100vh - 25rem)';

  return overviewData ? (
    <GeneralComponentErrorShield dataObjects={[overviewData, reportsList]}>
      <GridItem card xs={12}>
        <CustomTable<OverviewTableData>
          csvFields={
            clientName === 'adepa'
              ? adepaUcitsOverviewCsvFields
              : ucitsOverviewCsvFields
          }
          showToolbar
          loadingHeight={tableHeight}
          title={`UCITS Overview Data`}
          columns={columns}
          data={dataPreparedForTable}
          detailPanel={[
            {
              disabled: false,
              render: (rowData: OverviewTableData) => {
                return (
                  <OverviewSecondLevelGraphs
                    fundName={rowData.internalFundName}
                    positionDate={rowData.positionDate}
                    varFund={rowData.varFund === 'yes'}
                    passValue={mapStatusToColor(
                      specifyStatus(rowData.timeSeriesStatus)
                    )}
                  />
                );
              },
            },
          ]}
          options={{
            paging: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, dataPreparedForTable.length],
            search: true,
            exportButton: true,
            rowStyle: {
              padding: '1rem',
            },
            raptorExport: true,
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  ) : null;
};

export default UcitsOverview;
