import React, { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import CurrencyAssetsTable from './components/currencyAssetsTable.comopnent';
import { useDispatch } from 'react-redux';
import { addAdditionalTitleBarComponents } from '../../../../redux/pages/actions';
import { InfoToggle } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_INFOTOGGLE_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';

const Moab: FC<FundInfoComponentProps> = (props) => {
  const activeDate = props.positionDate;

  const moabData = useFetchData({
    keyName: makeUniqueDataKey(props.fundId, 'moab_data', props.positionDate),
    makeFetch: true,
    url: !activeDate
      ? `most_recently_stored_report_data_agg/${props.fundId}/moab_data2`
      : `stored_report_data_agg/${props.fundId}/moab_data2/${props.positionDate}`,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const infoTogglesToAdd: InfoToggle[] = [
      {
        titleBarTitle: 'Select Scenario',
        titleBarKey: 'scenarioSelection',
        displayOnOverviewPage: false,
        currentValue: '',
        message: (
          <p>
            This page shows the Maximum Achievable One-Day Balance of the fund.
            This balance is defined by the percentage of NAV the fund can
            liquidate in one day. The balance is categorised into M0, M1, M2 and
            M3.
            <br />
            <br />
            M0: Cash
            <br />
            M1: Cash, T-Bills
            <br />
            M2: Cash, All Government Bonds
            <br />
            M3: All Assets that can be liquidated in one day
          </p>
        ),
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        infoTogglesToAdd,
        APPEND_NEW_INFOTOGGLE_TO_TITLEBAR
      )
    );
  }, []);

  return (
    <GeneralComponentErrorShield dataObjects={[moabData]}>
      <CurrencyAssetsTable
        fundId={props.fundId}
        baseOrAll="base"
        title="Base Currency Assets"
        data={moabData}
      />
      <CurrencyAssetsTable
        fundId={props.fundId}
        title="All Currency Assets"
        baseOrAll="all"
        data={moabData}
      />
    </GeneralComponentErrorShield>
  );
};

export default Moab;
