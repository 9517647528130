import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC, ReactElement } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { flexify } from '../../../../utilities/cssMixins';
import { roundNumberToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface PeLiquidityHeatMapTableProps {
  data: any;
  setNumDays: Dispatch<any>;
  setMarketMove: Dispatch<any>;
  selectedMarketMove: number | string;
  selectedNumDays: number | string;
  // selectedPosition: string | null;
}

interface PeLiquidityHeatMapTableData {
  numDays: number | string;
  marketMove05: number;
  marketMove05Status: string;
  marketMove10: number;
  marketMove10Status: string;
  marketMove15: number;
  marketMove15Status: string;
  marketMove20: number;
  marketMove20Status: string;
  marketMove25: number;
  marketMove25Status: string;
  marketMove30: number;
  marketMove30Status: string;
  marketMove35: number;
  marketMove35Status: string;
  // marketMove80: number;
  // marketMove80Status: string;
}

const numMonthsValues = [1, 3, 6, 12, 24, 60, 120];
const percentageValues = [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35];
const markeyMoveKeys = [
  'marketMove05',
  'marketMove10',
  'marketMove15',
  'marketMove20',
  'marketMove25',
  'marketMove30',
  'marketMove35',
];
const marketMoveStatusKeys = [
  'marketMove05Status',
  'marketMove10Status',
  'marketMove15Status',
  'marketMove20Status',
  'marketMove25Status',
  'marketMove30Status',
  'marketMove35Status',
];

function buildPeLiquidityHeatMapData(data: any): PeLiquidityHeatMapTableData[] {
  if (!data.data.length) return [];
  const heatMapData = data.data[0];
  const tableData: PeLiquidityHeatMapTableData[] = [];
  interface rowType {
    [key: string]: number | string;
  }

  for (let i = 0; i < numMonthsValues.length; i++) {
    const rowData: rowType = {
      numDays: 0,
      marketMove05: 0,
      marketMove05Status: 'Pass',
      marketMove10: 0,
      marketMove10Status: 'Pass',
      marketMove15: 0,
      marketMove15Status: 'Pass',
      marketMove20: 0,
      marketMove20Status: 'Pass',
      marketMove25: 0,
      marketMove25Status: 'Pass',
      marketMove30: 0,
      marketMove30Status: 'Pass',
      marketMove35: 0,
      marketMove35Status: 'Pass',
      // marketMove80: 0,
      // marketMove80Status: 'Pass',
    };
    const currentStressValues = heatMapData[numMonthsValues[i]];
    rowData.numDays = numMonthsValues[i];
    for (let j = 0; j < percentageValues.length; j++) {
      rowData[markeyMoveKeys[j]] =
        currentStressValues[percentageValues[j]][numMonthsValues[i]][
          'Asset/Debt Ratio'
        ];
      rowData[marketMoveStatusKeys[j]] =
        currentStressValues[percentageValues[j]][numMonthsValues[i]]['status'];
    }
    tableData.push(rowData as unknown as PeLiquidityHeatMapTableData);
  }

  return tableData;
}

interface PEHeatMapCellProps {
  value: number;
  status: string;
  setNumDays: Dispatch<any>;
  setMarketMove: Dispatch<any>;
  numDays: number | string;
  marketMove: number | string;
  selectedMarketMove: number | string;
  selectedNumDays: number | string;
  text: string;
}

function getStatusColor(status: string) {
  switch (status) {
    case 'Pass':
      return mainColors.Pass;
    case 'Alert':
    case 'Pass-Alert':
      return mainColors.Alert;
    case 'Fail':
      return mainColors.Fail;
    default:
      return mainColors.Fail;
  }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    height: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    // maxWidth: '20rem',
    '&:hover': {
      backgroundColor: (props) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '20rem',
    // '&:hover': {
    //     backgroundColor: mainColors.Pass_darker,
    // },
  },
  cell: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function PELiquidityHeatMapCell(props: PEHeatMapCellProps): ReactElement {
  const {
    value,
    status,
    setNumDays,
    setMarketMove,
    numDays,
    marketMove,
    selectedMarketMove,
    selectedNumDays,
    text,
  } = props;
  const handleClick = () => {
    setNumDays(numDays);
    setMarketMove(marketMove);
  };
  return (
    <div
      style={{
        height: '8rem',
        backgroundColor: getStatusColor(status),
        borderColor:
          selectedMarketMove === marketMove && selectedNumDays === numDays
            ? mainColors.mainBlue
            : 'white',
        borderStyle: 'solid',
        borderWidth:
          selectedMarketMove === marketMove && selectedNumDays === numDays
            ? '4px'
            : '0.5px',
        cursor: 'pointer',
        ...flexify('center', 'center'),
      }}
      onClick={handleClick}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', fontSize: 16, fontWeight: 400 }}
      >
        {roundNumberToTwoDecimalPlaces(value)}
      </Typography>
    </div>
    //   </Wrapper>
  );
}

function getPeLiquidityHeatMapColumns(
  setNumDays: Dispatch<any>,
  setMarketMove: Dispatch<any>,
  selectedNumDays: string | number,
  selectedMarketMove: string | number
): CustomColumn<PeLiquidityHeatMapTableData>[] {
  return [
    {
      // title: 'Ebit Growth / EBIT',
      title: (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '10rem' }}></div>
            <div style={{}}>
              Market Move{' '}
              <ArrowForwardIcon sx={{ color: mainColors.mainBlue }} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '5rem' }}></div>
            <div style={{}}>
              Number of Months{' '}
              <ArrowDownwardIcon sx={{ color: mainColors.mainBlue }} />
            </div>
            <div></div>
          </div>
        </div>
      ),
      field: 'numDays',
      cellStyle: {
        textAlign: 'center',
      },
      // headerStyle: {
      //     textAlign: 'center',
      // },
    },
    {
      title: '-5%',
      field: 'marketMove05',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove05}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove05)}
          status={rowData.marketMove05Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.05}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-10%',
      field: 'marketMove10',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove10}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove10)}
          status={rowData.marketMove10Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.1}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    // {
    //     title: '15%',
    //     field: 'marketMove15',
    //     render: (rowData: PeLiquidityHeatMapTableData) => (
    //         <PELiquidityHeatMapCell
    //             value={rowData.marketMove15}
    //             status={rowData.marketMove15Status}
    //         />
    //     ),
    //     cellStyle: {
    //         textAlign: 'center',
    //         padding: 0,
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //     },
    // },
    {
      title: '-15%',
      field: 'marketMove15',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove15}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove15)}
          status={rowData.marketMove15Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.15}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-20%',
      field: 'marketMove20',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove20}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove20)}
          status={rowData.marketMove20Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.2}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-25%',
      field: 'marketMove25',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove25}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove25)}
          status={rowData.marketMove25Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.25}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-30%',
      field: 'marketMove30',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove30}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove30)}
          status={rowData.marketMove30Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.3}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-35%',
      field: 'marketMove35',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove35}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove35)}
          status={rowData.marketMove35Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.35}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    // {
    //     title: '-80%',
    //     field: 'stress80',
    //     render: (rowData: PeLiquidityHeatMapTableData) => (
    //         <PELiquidityHeatMapCell
    //             value={rowData.marketMove80}
    //             status={rowData.marketMove80Status}
    //             setNumDays={setNumDays}
    //             setMarketMove={setMarketMove}
    //             numDays={rowData.numDays}
    //             marketMove={0.8}
    //             selectedNumDays={selectedNumDays}
    //             selectedMarketMove={selectedMarketMove}
    //         />
    //     ),
    //     cellStyle: {
    //         textAlign: 'center',
    //         padding: 0,
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //     },
    // },
  ];
}
function getPeLiquidityHeatMapColumnsPDF(
  setNumDays: Dispatch<any>,
  setMarketMove: Dispatch<any>,
  selectedNumDays: string | number,
  selectedMarketMove: string | number
): CustomColumn<PeLiquidityHeatMapTableData>[] {
  return [
    {
      // title: 'Ebit Growth / EBIT',
      title: 'Number of Months / Market Move',
      field: 'numDays',
      cellStyle: {
        textAlign: 'center',
      },
      width: '15%',
      // headerStyle: {
      //     textAlign: 'center',
      // },
    },
    {
      title: '-5%',
      field: 'marketMove05',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove05}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove05)}
          status={rowData.marketMove05Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.05}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-10%',
      field: 'marketMove10',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove10}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove10)}
          status={rowData.marketMove10Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.1}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    // {
    //     title: '15%',
    //     field: 'marketMove15',
    //     render: (rowData: PeLiquidityHeatMapTableData) => (
    //         <PELiquidityHeatMapCell
    //             value={rowData.marketMove15}
    //             status={rowData.marketMove15Status}
    //         />
    //     ),
    //     cellStyle: {
    //         textAlign: 'center',
    //         padding: 0,
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //     },
    // },
    {
      title: '-15%',
      field: 'marketMove15',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove15}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove15)}
          status={rowData.marketMove15Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.15}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-20%',
      field: 'marketMove20',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove20}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove20)}
          status={rowData.marketMove20Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.2}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-25%',
      field: 'marketMove25',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove25}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove25)}
          status={rowData.marketMove25Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.25}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-30%',
      field: 'marketMove30',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove30}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove30)}
          status={rowData.marketMove30Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.3}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-35%',
      field: 'marketMove35',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: PeLiquidityHeatMapTableData) => (
        <PELiquidityHeatMapCell
          value={rowData.marketMove35}
          text={roundNumberToTwoDecimalPlaces(rowData.marketMove35)}
          status={rowData.marketMove35Status}
          setNumDays={setNumDays}
          setMarketMove={setMarketMove}
          numDays={rowData.numDays}
          marketMove={0.35}
          selectedNumDays={selectedNumDays}
          selectedMarketMove={selectedMarketMove}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    // {
    //     title: '-80%',
    //     field: 'stress80',
    //     render: (rowData: PeLiquidityHeatMapTableData) => (
    //         <PELiquidityHeatMapCell
    //             value={rowData.marketMove80}
    //             status={rowData.marketMove80Status}
    //             setNumDays={setNumDays}
    //             setMarketMove={setMarketMove}
    //             numDays={rowData.numDays}
    //             marketMove={0.8}
    //             selectedNumDays={selectedNumDays}
    //             selectedMarketMove={selectedMarketMove}
    //         />
    //     ),
    //     cellStyle: {
    //         textAlign: 'center',
    //         padding: 0,
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //     },
    // },
  ];
}

interface minMaxValues {
  minValue: number;
  maxValue: number;
}

function getMinMaxPL(data: any, selectedPosition: string | null): minMaxValues {
  if (!selectedPosition) return { minValue: 0, maxValue: 0 };
  if (!data.data.length) return { minValue: 0, maxValue: 0 };
  if (!(selectedPosition in data.data[0])) return { minValue: 0, maxValue: 0 };
  const heatMapData = data.data[0][selectedPosition];
  let minValue = heatMapData[0][0]['p/l'];
  let maxValue = heatMapData[0][0]['p/l'];

  for (let i = 0; i < heatMapData[0].length; i++) {
    const currentStressValues = heatMapData[i];
    for (let j = 0; j < currentStressValues.length; j++) {
      if (minValue > currentStressValues[j]['p/l']) {
        minValue = currentStressValues[j]['p/l'];
      }
      if (maxValue < currentStressValues[j]['p/l']) {
        maxValue = currentStressValues[j]['p/l'];
      }
    }
  }
  return { minValue, maxValue };
}

const PeLiquidityHeatMapTable: FC<PeLiquidityHeatMapTableProps> = (props) => {
  // const theme = useTheme();
  const {
    data,
    setNumDays,
    setMarketMove,
    selectedNumDays,
    selectedMarketMove,
  } = props;
  // const { minValue, maxValue } = getMinMaxPL(data, selectedPosition);
  const tableData = buildPeLiquidityHeatMapData(data);
  const PeHeatMapColumns = getPeLiquidityHeatMapColumns(
    setNumDays,
    setMarketMove,
    selectedNumDays,
    selectedMarketMove
  );
  const pdfColumns = getPeLiquidityHeatMapColumnsPDF(
    setNumDays,
    setMarketMove,
    selectedNumDays,
    selectedMarketMove
  );

  return (
    <GridItem xs={12} card style={{ padding: 8 }}>
      {/* <Typography variant='h2' style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}>
                {"PE Heat Map"}
            </Typography> */}
      <CustomTable<PeLiquidityHeatMapTableData>
        pdfTitle="Liquidity Stress Tests Heat Map"
        columns={PeHeatMapColumns}
        pdfColumns={pdfColumns}
        id="liquidity_stress_tests_heat_map"
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: {
            marginLeft: 0,
          },
          sorting: false,
          draggable: false,
          toolbar: true,
        }}
      />
    </GridItem>
  );
};

export default PeLiquidityHeatMapTable;
