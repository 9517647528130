import React, { FC } from 'react';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import CisTable, { ExposureType } from './table/cisTable.component';
import useFetchData from '../../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import CisMaps from './maps/CisMaps';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';

const MancoCis: FC<GeneralOverviewPageRenderedComponentProps> = (props) => {
  const { positionDate } = props;
  const exposureType = useTitleBarSelect('exposureType');

  const selectedVisualisation = useTitleBarSelect('selectVisualisation');

  // const url = positionDate
  //   ? `stored_report_data_agg/${fundId}/cis_specific_data/${positionDate}`
  //   : `most_recently_stored_report_data_agg/${fundId}/cis_specific_data`;

  const cisData = useFetchData({
    keyName: `manco_asset_class_cis_data_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: `manco_level_asset_class_data/cis${
      positionDate ? '/' + positionDate : ''
    }`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[cisData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      {selectedVisualisation === 'positionTable' ? (
        <CisTable
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      ) : (
        <CisMaps
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      )}
    </GeneralComponentErrorShield>
  );
};

export default MancoCis;
