import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import CisTable, { ExposureType } from './table/cisTable.component';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import CisMaps from './maps/CisMaps';

const Cis: FC<FundInfoComponentProps> = (props) => {
  const { fundId, positionDate } = props;
  const exposureType = useTitleBarSelect('exposureType');

  const selectedVisualisation = useTitleBarSelect('selectVisualisation');

  const url = positionDate
    ? `stored_report_data_agg/${fundId}/cis_specific_data/${positionDate}`
    : `most_recently_stored_report_data_agg/${fundId}/cis_specific_data`;

  const cisData = useFetchData({
    keyName: `cisDate_${fundId}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    // url: `${assetClassUrls.FETCH_EQUITY_DATA_FIRST_HALF}/${fundId}/cis_specific_data`,
    url: url,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[cisData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      {selectedVisualisation === 'positionTable' ? (
        <CisTable
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      ) : (
        <CisMaps
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      )}
    </GeneralComponentErrorShield>
  );
};

export default Cis;
