import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { RaptorTheme } from '../../../../styling/theme';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import RaptorLoading from '../../../feedback/RaptorLoading';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import RiskComparisonTable from './table';
import dayjs from 'dayjs';
import useTitleBarDatePicker from '../../../../hooks/useTitleBarDatePicker';

import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { APPEND_NEW_DATE_PICKER_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import VarChangesTable from './VarChangesTable.component';

// make Styles is an API provided by Material UI to specify styling
const useRiskComparisonStyles = makeStyles((theme: RaptorTheme) => ({
  tabsRoot: {
    alignItems: 'center',
  },
  tabRoot: {
    zIndex: 2,
    borderRadius: 8,
    transition: 'all .3s',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[200],
    },
  },
  indicator: {
    zIndex: 1,
    height: '100%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    display: 'flex',
    color: theme.palette.primary.main,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: 'inherit',
      backgroundColor: theme.palette.grey[200],
      borderRadius: 8,
    },
  },
  flxContainer: {},
}));

function buildRiskComparisonUrl(
  referenceDate: string | null,
  comparisonDate: string | null,
  selectedValue: string | null,
  activeFund: string
) {
  switch (selectedValue) {
    case 'positionChanges':
      return `position_differences_agg/${referenceDate}/${comparisonDate}/${activeFund}`;
    case 'exposureChanges':
      return `interday_position_exposure_analysis_historical_agg/${activeFund}/${referenceDate}/${comparisonDate}/all`;
    case 'varChanges':
      return `position_var_differences/${activeFund}/${referenceDate}/${comparisonDate}`;
    // return `interday_position_risk_analysis_historical_agg/${activeFund}/${referenceDate}/${comparisonDate}/all`;
    default:
      return '';
  }
}

const RiskComparison: FC<FundInfoComponentProps> = (props) => {
  const classes = useRiskComparisonStyles();

  const { fundId, availableDates } = props;

  const dispatch = useDispatch();
  const datesForSelects = useFetchData({
    url: formatFundUrl('get_fund_position_dates', fundId),
    keyName: makeUniqueDataKey(fundId, 'comparisonDates'),
    makeFetch: true,
  });

  const selectedSection = useTitleBarSelect('changesSelection');

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (datesForSelects && !datesForSelects.isFetching) {
      const datePickersToAdd: DatePicker[] = [
        {
          displayOnOverviewPage: true,
          titleBarKey: 'fromDate',
          titleBarTitle: 'From Date:',
          currentValue: dayjs(
            datesForSelects.data[0][1] ?? datesForSelects.data[0][0]
          ).format('YYYY-MM-DD'),
          availableDates: datesForSelects.data[0],
        },
        {
          displayOnOverviewPage: true,
          titleBarKey: 'toDate',
          titleBarTitle: 'To Date:',
          currentValue: dayjs(datesForSelects.data[0][0]).format('YYYY-MM-DD'),
          availableDates: datesForSelects.data[0],
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          datePickersToAdd,
          APPEND_NEW_DATE_PICKER_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [datesForSelects]);

  const fromDate = useTitleBarDatePicker('fromDate');
  const toDate = useTitleBarDatePicker('toDate');

  const dataCanBeFetched = fromDate !== null && toDate !== null;

  // Only pull data for the section that is selected.
  const url = buildRiskComparisonUrl(fromDate, toDate, selectedSection, fundId);

  const changesData = useFetchData({
    url: url,
    keyName: `${fundId}_${fromDate}_${toDate}_${selectedSection}_analysis`,
    makeFetch: dataCanBeFetched,
  });

  const ready = changesData;
  datesForSelects && fromDate !== null && toDate !== null;

  const loading =
    ready && (changesData.isFetching || datesForSelects.isFetching);

  return ready ? (
    !loading ? (
      <GeneralComponentErrorShield
        dataObjects={[datesForSelects, changesData]}
        customLoadingMessages={['Loading Comparison Data...']}
      >
        {selectedSection === 'varChanges' ? (
          <VarChangesTable
            data={changesData}
            comparisonDate={fromDate ?? ''}
            referenceDate={toDate ?? ''}
          />
        ) : (
          <RiskComparisonTable
            referenceDate={fromDate || ''}
            comparisonDate={toDate || ''}
            selectedSection={selectedSection || 'positionChanges'}
            data={changesData}
            classes={classes}
          />
        )}
      </GeneralComponentErrorShield>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};
export default RiskComparison;
