import React, { FC, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import LiquidityStressTestsGraph from './LiquidityStressTestsGraph.component';
import PeLiquidityHeatMapTable from './LiquidityStressTestsHeatMap.component';
import PeLiquidityTable from './PeLiquidityStressTestsTable.component';

const PeLiquidityStressTests: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const PeLiquidityData = useFetchData({
    url: `pe_liquidity_stress_test/${fundId}/2022-09-30`,
    keyName: `pe_liquidity_stress_test_data_${fundId}_${positionDate}`,
    makeFetch: true,
  });

  const [numDays, setNumDays] = useState<any>(0);
  const [marketMove, setMarketMove] = useState<any>(0);

  return (
    <GeneralComponentErrorShield
      dataObjects={[PeLiquidityData]}
      customLoadingMessages={['Loading Private Equity Data...']}
      customErrorMessage="No Data Available."
    >
      <PeLiquidityHeatMapTable
        data={PeLiquidityData}
        setNumDays={setNumDays}
        setMarketMove={setMarketMove}
        selectedNumDays={numDays}
        selectedMarketMove={marketMove}
      />
      {numDays > 0 && marketMove !== 0 ? (
        <LiquidityStressTestsGraph
          data={PeLiquidityData}
          numDays={numDays}
          marketMove={marketMove}
        />
      ) : null}
      <PeLiquidityTable
        data={PeLiquidityData}
        numDays={numDays}
        marketMove={marketMove}
      />
    </GeneralComponentErrorShield>
  );
};

export default PeLiquidityStressTests;
