import { TitleBarComponents } from '../redux/pages/PagesStore';

/*
The client configuration corresponds
to the configs generated by the API.

*/

export interface ClientConfigurationReceivedFromServer {
  openingPage: string | null;
  // the client's name
  configId: string;
  sections: Section[] | null;
  pages: {
    [key: string]: Page;
  } | null;
}

export interface ClientConfiguration
  extends ClientConfigurationReceivedFromServer {
  isFetching: boolean;
  error: null | Error;
  allowedPageRoutesWherePagesAllowFunds: string[];
  allowedFundRoutes: string[];
}
export type Fund = {
  id: string;
  name: string;
};

export enum FundType {
  UCITS = 'UCITS',
  AIF = 'AIF',
  SIF = 'SIF',
  RAIF = 'RAIF',
  ACT40 = 'ACT40', // the enum doesn't allow numbers at the beginning, so we use ACT40 instead of 40ACT
  FIVG = 'FIVG',
  NURS = 'NURS',
  RIAIF = 'RIAIF',
  PARTII = 'PARTII',
  QIAIF = 'QIAIF',
  AIF_PE = 'AIF_PE',
  no_valid = 'no_valid',
}

export interface SectionFromServer {
  sectionId: string;
  sectionTitle: string;
  fundTypes: string;
  rsResources: string;
  // describes which pages appear in which section
  pages: string[];
}
export interface Section extends SectionFromServer {
  // derived from the sectionId
  sectionRoute: string;
}

export interface PageFromServer {
  pageId: string;
  pageTitle: string;
  iconKey: IconKey;
  pageArea: string;
  allowFundPages: boolean;
  useFundPicker: boolean;
  pageKey: string;
  usePositionDatePicker: boolean;
  usePositionDatePickerRange: boolean;
  titleBarComponents?: TitleBarComponents;
  locked: boolean;
  exportable: boolean;
}
export interface Page extends PageFromServer {
  // derived from pageId
  pageRoute: string;
}

// NOTE: These icon keys correspond to the IconKey enum in the
// common.api_models.raptor_models.icon_key module
export enum IconKey {
  KIID = 'kiid',
  WARNING = 'warning',
  BOOK = 'book',
  SUB_FUND = 'sub-fund',
  OPEN_WIDTH = 'open_width',
  SHUFFLE = 'shuffle',
  SHARE_CLASS = 'share_class',
  EYE = 'eye',
  NOTES = 'notes',
  TOLL = 'toll',
  POSITION_CHANGES = 'position_changes',
  MOAB = 'moab',
  CANCEL = 'cancel',
  RST_FIXED = 'rst_fixed',
  RST_VARIABLE = 'rst_variable',
  LIQUIDATION_RISK_OVERVIEW = 'liquidation_risk_overview',
  LIQUIDITY_OVERVIEW = 'liquidity_overview',
  LIQUIDATION_TIME = 'liquidation_time',
  RIGHT_TURN = 'right_turn',
  EXPOSURE = 'exposure',
  FULL_CLOUD = 'full_cloud',
  LAW = 'law',
  CLOUD_UP = 'cloud_up',
  CIS = 'cis',
  RISK = 'risk',
  VAR = 'var',
  COUNTERPARTY = 'counterparty',
  HISTORICAL = 'historical',
  BACKTEST = 'backtest',
  COMPARISON = 'comparison',
  STRESS_TEST = 'stressTest',
  REPORTS = 'reports',
  FAIL = 'fail',
  STATUS_CHANGE = 'statusChange',
  CHART = 'chart',
  CURRENCY = 'currency',
  FIXED_INCOME = 'fixedIncome',
  FUNCTIONALITY = 'functionality',
  ECO = 'eco',
  GRAPH_SQUARE = 'graph-square',
  GROUP = 'group',
  PREVIEW = 'preview',
  INSIGHTS = 'insights',
  MULTIPLE_STOP = 'multipleStop',
  CASHFLOW = 'cashflow',
  HEAT = 'heat',
  TIMELINE = 'timeline',
  DASHBOARD = 'dashboard',
  SHUFFLE_ON = 'shuffle-on',
  SQUARE = 'square',
  RADAR = 'radar',
  BAD = 'bad',
  FACTORY = 'factory',
  DOWNLOAD = 'download',
  FEED = 'feed',
  SWAP_CALLS = 'swap-calls',
  MONITOR_HEART = 'monitor-heart',
  BULLET_LIST = 'bullet-list',
  LEADERBOARD = 'leaderboard',
  NUMBERS = 'numbers',
  FACT_CHECK = 'fact-check',
  STORE = 'store',
  ALIGN_HORIZONTAL_LEFT = 'align-horizotnal-left',
  SEARCH = 'search',
  ALL_INBOX = 'all-inbox',
  TABLE_ROWS = 'table-rows',
  START = 'start',
  HISTORY = 'history',
  ASSURED_WORKLOAD = 'assured-workload',
}
