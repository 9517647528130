import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react';

interface RaptorMultiSelectOption {
  label: string;
  value: string;
}

interface RaptorMultiSelectProps {
  items: RaptorMultiSelectOption[];
  label: string;
  placeholder: string;
  selectAllLabel: string;
  noOptionsText: string;
  limitTags: number;
  onChange: (selectedOptions: RaptorMultiSelectOption[]) => void;
}

const RaptorMultiSelect = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
}: RaptorMultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    RaptorMultiSelectOption[]
  >([]);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions: RaptorMultiSelectOption[]) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option: any) => `${option.label}`;
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event: any, selectedOptions: any, reason: any) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (
        selectedOptions.find(
          (option: RaptorMultiSelectOption) => option.value === 'select-all'
        )
      ) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== 'select-all');
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option: any, { selected }: any) => {
    const selectAllProps =
      option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params: any) => (
    <TextField {...params} label={label} placeholder={placeholder} />
  );
  const getOptionSelected = (option: any, anotherOption: any) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      style={{ width: '800px' }}
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      // getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        return [{ label: selectAllLabel, value: 'select-all' }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

RaptorMultiSelect.defaultProps = {
  limitTags: 3,
  items: [],
  selectedValues: [],
  getOptionLabel: (value: any) => value,
};

export default RaptorMultiSelect;
