import { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { useSelector } from 'react-redux';
import GridItem from '../../../layout/GridComponents/GridItem';
import { flexify } from '../../../../utilities/cssMixins';
import DataContainer from './historicalComponents/data-container/data-container.component';
import { createSpecificTitleBarSelectSelector } from '../../../../redux/pages/selectors';
import {
  useAdditionalTitleBarDatePicker,
  useAdditionalTitleBarSelect,
} from '../../../../hooks/useAdditionalTitleBarComponents';
import { historicalDataKeys } from './historicalComponents/controlBar/components/key-select/key-select.component';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useTitleBarDatePicker from '../../../../hooks/useTitleBarDatePicker';
import { useRemoveTitleBarSelect } from '../../../../hooks/useRemoveTitleBarComponent';
import dayjs from 'dayjs';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import { Grid, Typography } from '@mui/material';

const generateKeyString = (key1: string, key2: string) => {
  if (key1 === 'none_selected') {
    if (key2 === 'none_selected') {
      return null;
    } else {
      return key2;
    }
  } else {
    if (key2 !== 'none_selected') {
      return key1 + '/' + key2;
    } else {
      return key1;
    }
  }
};

const HistoricalData: FC<FundInfoComponentProps> = (props) => {
  // setup datepickers
  const startDateWeekday = getWeekdayXMonthsAgo(3);
  const endDateWeekday = getMostRecentWeekday();
  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'startDate',
    titleBarTitle: 'Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
  });
  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'endDate',
    titleBarTitle: 'End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
  });

  // view configuration selector
  const viewConfigurationSelector = createSpecificTitleBarSelectSelector(
    'viewConfigurationHistorical'
  );
  const viewConfiguration = useSelector(viewConfigurationSelector);
  const showFour = viewConfiguration[0]?.currentValue === 'four' || false;

  // setup keys selectors
  useAdditionalTitleBarSelect(
    {
      displayOnOverviewPage: true,
      titleBarKey: 'key1',
      titleBarTitle: 'Key 1: ',
      values: historicalDataKeys.map((key) => {
        return {
          label: key
            .split('_')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' '),
          value: key,
        };
      }),
      aValueIsAlwaysSelected: true,
      currentValue: historicalDataKeys[0],
    },
    true,
    !showFour
  );
  useAdditionalTitleBarSelect(
    {
      displayOnOverviewPage: true,
      titleBarKey: 'key2',
      titleBarTitle: 'Key 2: ',
      values: historicalDataKeys.map((key) => {
        return {
          label: key
            .split('_')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' '),
          value: key,
        };
      }),
      aValueIsAlwaysSelected: true,
      currentValue: historicalDataKeys[1],
    },
    true,
    !showFour
  );

  const key1 = useTitleBarSelect('key1') || historicalDataKeys[0];
  const key2 = useTitleBarSelect('key2') || historicalDataKeys[1];
  const fromDate = useTitleBarDatePicker('startDate');
  const toDate = useTitleBarDatePicker('endDate');

  const topLevel = {
    key1,
    key2,
    fromDate,
    toDate,
  };
  // create defualt selections for when the view is switched to four charts
  const fourChartsTopLevels = [
    { key1, key2, fromDate, toDate },
    {
      key1: 'portfolio_asset_class_equity_gross_exposure',
      key2: 'portfolio_asset_class_equity_net_exposure',
      fromDate,
      toDate,
    },
    {
      key1: 'portfolio_asset_class_fixed_income_gross_exposure',
      key2: 'portfolio_asset_class_net_fixed_income_exposure',
      fromDate,
      toDate,
    },
    {
      key1: 'portfolio_asset_class_alternative_gross_exposure',
      key2: 'portfolio_asset_class_alternative_net_exposure',
      fromDate,
      toDate,
    },
  ];

  // Check the value of the key1 select (null should indicate the select is not showing)
  const selectKey1Value = useTitleBarSelect('key1');
  const removeKey1 = useRemoveTitleBarSelect('key1');
  // Check the value of the key2 select (null should indicate the select is not showing)
  const selectKey2Value = useTitleBarSelect('key2');
  const removeKey2 = useRemoveTitleBarSelect('key2');
  if (showFour && selectKey1Value !== null && selectKey2Value !== null) {
    removeKey1();
    removeKey2();
  }
  const ready = key1 && key2 && fromDate && toDate;

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ margin: 0 }}
        id={showFour ? 'pdf-target' : 'grid'}
      >
        {ready ? (
          showFour ? (
            [0, 1, 2, 3].map((num) => (
              <DataContainer
                key={num + 'historicalDataContainer'}
                itemIdentifier={num}
                fourShowing={showFour}
                topLevel={fourChartsTopLevels[num]}
                activeFund={props.fundId}
                external={false}
              />
            ))
          ) : (
            <>
              <DataContainer
                itemIdentifier={0}
                fourShowing={showFour}
                topLevel={topLevel}
                activeFund={props.fundId}
                fixedTitle="Time Series"
                external={true}
              />
              <DataContainer
                itemIdentifier={1}
                fourShowing={showFour}
                topLevel={topLevel}
                activeFund={props.fundId}
                fixedTitle="Table"
                external={true}
                noLoader
              />
            </>
          )
        ) : (
          <GridItem
            xs={12}
            style={{
              height: 'calc(100vh - 22rem)',
              ...flexify('center', 'center'),
            }}
          >
            <Typography variant="h1">
              {props.fundId !== 'none_selected'
                ? 'Please use the keys and dates to query data'
                : 'Please select  a fund to begin'}
            </Typography>
          </GridItem>
        )}
      </Grid>
    </>
  );
};

export default HistoricalData;
