import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSnackbar from '../../../../hooks/useSnackbar';
import {
  activeSectionSelector,
  createSectionFundsSelector,
} from '../../../../redux/pages/selectors';
import { RaptorTheme, greys } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import client, { BASE_URL } from '../../../../utilities/requestClient';
import GridItem from '../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../selects/GeneralSelect';
import FunctionalityButton from '../../ucits/functionality/functionalityComponents/functionalityButton';
import { Typography } from '@mui/material';

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

// For now this page will only be used with gemini
function getReportUrl(fundId: string): string {
  const geminiFunds = [
    'gemini001',
    'gemini002',
    'gemini003',
    'gemini004',
    'gemini005',
    'gemini006',
    'gemini007',
    'gemini301',
    'gemini801',
    'gemini802',
    'gemini901',
  ];

  if (geminiFunds.indexOf(fundId) > -1) {
    return (
      BASE_URL +
      'generate_gemini_report/' +
      fundId +
      '/' +
      'ucits_monthly_review1.html,' +
      'compliance_monthly_review1.html,' +
      'risk_overview2_pdf.html,' +
      'historical_data_eom.html,' +
      'AggregateCommitment2.html,' +
      'Stress_Test_PDF.html,' +
      'Proxy_SRRI1_PDF.html' +
      '/120/0.7/2'
    );
  } else if (fundId === 'gemini101' || fundId === 'gemini102') {
    return (
      BASE_URL +
      'generate_gemini_report/' +
      fundId +
      '/' +
      'ucits_monthly_review1.html,' +
      'compliance_monthly_review1.html,' +
      'risk_overview2_pdf.html,' +
      'historical_data_eom.html,' +
      'AggregateVaR1.html,' +
      'BackTest_PDF_EOM.html,' +
      'Stress_Test_PDF.html,' +
      'Proxy_SRRI1_PDF.html' +
      '/80/0.7/2'
    );
  } else if (fundId === 'gemini401' || fundId === 'gemini501') {
    return (
      BASE_URL +
      'generate_gemini_report/' +
      fundId +
      '/' +
      'ucits_monthly_review1.html,' +
      'compliance_monthly_review1.html,' +
      'risk_overview2_pdf.html,' +
      'historical_data_eom.html,' +
      'AggregateVaR1.html,' +
      'BackTest_PDF_EOM.html,' +
      'Stress_Test_PDF.html,' +
      'Proxy_SRRI1_PDF.html' +
      '/180/0.7/2'
    );
  } else if (fundId === 'gemini701') {
    return (
      BASE_URL +
      'generate_gemini_report/' +
      fundId +
      '/' +
      'ucits_monthly_review1.html,' +
      'compliance_monthly_review1.html,' +
      'risk_overview2_pdf.html,' +
      'historical_data_eom.html,' +
      'AggregateCommitment2.html,' +
      'Stress_Test_PDF.html,' +
      'Risk_CS2.html,' +
      'Proxy_SRRI1_PDF.html' +
      '/180/0.7/2'
    );
  } else {
    return '';
  }
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  fdiContainer: {
    display: 'flex',
    position: 'relative',
    top: '1rem',
    marginBottom: '1rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
  },
  header: {
    position: 'relative',
    // top: '0.5rem',
  },
}));

const ReportGeneration: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  // Classes for fdiContainer
  const classes = useStyles();
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits')
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];

  const [currentSelection, setCurrentSelection] = useState('none_selected');
  function updateSelection(selection: string) {
    setCurrentSelection(selection);
  }

  // Create a function for generating the url and calling the report when the button is pressed
  const requestClient = client();
  const { showAsyncSnackbar } = useSnackbar();
  async function handleRequest() {
    const url = getReportUrl(currentSelection);
    const reportGenerator = openInNewTab(url);
    await showAsyncSnackbar({
      startMessage: 'Running Monthly Report',
      successMessage: 'Report Finshed',
      failureMessage: 'Failed to generate report',
      promiseToResolve: reportGenerator(),
    });
    // await requestClient.get(url);
  }

  return (
    <GridItem card xs={12} cardStyle={{ height: '100%', padding: '1rem' }}>
      <div className={classes.header}>
        <Typography
          variant="h2"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
          className={classes.header}
        >
          Monthly Report Generation
        </Typography>
      </div>
      <div className={classes.fdiContainer}>
        <GeneralSelect
          labels={fundNames}
          values={fundIds}
          selected={currentSelection}
          setSelected={updateSelection}
          placeholderValue="None Selected"
          placeholderColor={greys.grey900}
          selectTitle={'Select a fund'}
          customStyles={{
            pickerRoot: classes.pickerRoot,
          }}
        />
        <FunctionalityButton
          handler={handleRequest}
          title="Run Monthly Report"
        />
      </div>
    </GridItem>
  );
};

export default ReportGeneration;
