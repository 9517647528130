import React, { FC } from 'react';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import AggregateLiquidationTable from './table/ag-table.component';
import { StressLevel } from '../fundAssetLiquidationTime/table';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { useSelector } from 'react-redux';
import { activeSectionSelector } from '../../../../redux/pages/selectors';

export const liquidityMap: { [key: string]: { id: number; name: string } } = {
  standard: {
    id: 0,
    name: 'Standard',
  },
  stressed: {
    id: 1,
    name: 'Stressed',
  },
  superStressed: {
    id: 2,
    name: 'Super Stressed',
  },
};
const AggregateLiquidation: FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const stressLevel = useTitleBarSelect('stressLevel');
  const activeSection = useSelector(activeSectionSelector);
  const fetchUrl =
    'aggregate_liquidation_risk_report/' +
    (activeSection ? activeSection.toUpperCase() : 'UCITS');

  const { positionDate } = props;
  const liquidityData = useFetchData({
    keyName: `agg_liquidation_${stressLevel || 'standard'}_${
      positionDate || formatDateForCheckingState(new Date())
    }_${activeSection ? activeSection.toUpperCase() : 'UCITS'}`,
    url: stressLevel
      ? `${fetchUrl}/${liquidityMap[stressLevel].id}${
          positionDate ? `/${positionDate}` : ''
        }`
      : '',
    makeFetch: stressLevel !== null,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[liquidityData]}>
      <AggregateLiquidationTable
        selectedLevel={(stressLevel as StressLevel) || 'standard'}
        liquidityData={liquidityData}
      />
    </GeneralComponentErrorShield>
  );
};

export default AggregateLiquidation;
