import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import VarNewMainChart from './VarNewMainChart/VarNewMainChart.component';
import VarNewMainTable from './VarNewMainTable/VarNewMainTable.component';
import VarNewBreakdownTableComponent from './varNewBreakdownTable/VarNewBreakdownTable.component';

const ValueAtRiskNew: React.FC<FundInfoComponentProps> = ({
  section,
  pageId,
  fundName,
  fundId,
  ...props
}) => {
  const basicDataAggFetchString = `basic_data_agg/${fundId}/${
    props.positionDate || ''
  }`;
  const portfolioNameAggString = `portfolio_name_agg/${fundId}/`;
  const fundVarDataString = `fund_VaR_data/${fundId}/${
    props.positionDate || ''
  }`;
  const basicDataAgg = useFetchData({
    url: basicDataAggFetchString,
    keyName: `basic_data_agg_var_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const portfolioNameAgg = useFetchData({
    url: portfolioNameAggString,
    keyName: `portfolio_name_agg_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const fundVarData = useFetchData({
    url: fundVarDataString,
    keyName: `fund_var_data_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const breakdownData = useFetchData({
    url: `/position_var_table/${fundId}/${props.positionDate}`,
    keyName: `position_var_table_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: props.positionDate ? true : false,
  });
  return (
    <GeneralComponentErrorShield
      customLoadingMessages={[
        'Loading your var data',
        'This may take a few minutes...',
      ]}
      dataObjects={[basicDataAgg, portfolioNameAgg, fundVarData, breakdownData]}
    >
      <VarNewMainTable
        fundName={fundName}
        dataObjects={{ basicDataAgg, portfolioNameAgg, fundVarData }}
      />
      <VarNewMainChart
        fundName={fundName}
        dataObjects={{ basicDataAgg, portfolioNameAgg, fundVarData }}
      />
      <VarNewBreakdownTableComponent
        data={breakdownData}
        positionDate={props.positionDate ?? ''}
      />
    </GeneralComponentErrorShield>
  );
};

export default ValueAtRiskNew;
