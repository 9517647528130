import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import FxRiskTable from './fxRiskTable.component';
import FxRiskStressScenarioTable from './fxRiskStressTable.component';
import { Grid } from '@mui/material';

const FXRisk: FC<FundInfoComponentProps> = (props) => {
  const activeDate = props.positionDate;

  const urlForFetch = !activeDate
    ? `/stored_fx_stress_test_var_data_agg/${props.fundId}`
    : `/stored_fx_stress_test_var_data_agg/${props.fundId}/${activeDate}`;

  const fxRiskData = useFetchData({
    keyName: makeUniqueDataKey(props.fundId, 'fx_risk', activeDate),
    url: urlForFetch,
    makeFetch: true,
  });
  const section = props.section;
  const pageId = props.pageId;
  const fundId = props.fundId;
  const fundName = props.fundName;

  return (
    <GeneralComponentErrorShield
      dataObjects={[fxRiskData]}
      customLoadingMessages={['Loading FX Risk Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container>
        <FxRiskStressScenarioTable
          data={fxRiskData}
          section={section}
          pageId={pageId}
          fundId={fundId}
          fundName={fundName}
        />
        <FxRiskTable data={fxRiskData} title={'FX Risk Exposure'} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default FXRisk;
