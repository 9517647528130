import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import LasdabChart from './chart';
import LasdabTable from './table';

export const LiquidityUrls = {
  FETCH_FUND_ASSET_LIQUIDITY: 'agg_liquidation_zone_inc_cash',
  FETCH_FUND_LIQUIDATION_TIME_COST: 'aggregate_liquidity_risk_report_data/2',
  FETCH_FUND_LIQUIDITY_RISK_OVERVIEW: 'aggregate_liquidity_risk_report_data/3',
  FETCH_EVOLUTION: 'aggregate_lqdty_review_data',
  FETCH_RST: 'historical_liquidity_reverse_stress_tests_agg',
};

export const scenarioMapGeneral: {
  [key: string]: string;
} = {
  standard: 'Standard',
  stressed: 'Stressed',
  superStressed: 'Super Stressed',
};
export const scenarioMapGem: {
  [key: string]: string;
} = {
  standard: 'Standard',
  stressed: 'Stressed',
  superStressed: 'Super Stressed',
  euSovDebtCrisisJul11: 'EU Sov Debt Crisis Jul11',
  creditCrisisSept2008: 'Credit Crisis Sept08',
};

const LASDAB_ULR =
  'most_recently_stored_report_data_agg/<fund_id>/cpl_risk/lasdab';
const LASDAB_ULR_HISTORICAL =
  'most_recently_stored_report_data_agg/<fund_id>/cpl_risk/lasdab';
const LiquidityAdjustedSettlementDateAchievableBalance: FC<
  FundInfoComponentProps
> = (props) => {
  const activeFund = props.fundId;
  const activeDate = props.positionDate;
  // Get the user Id (so we can display different options for GEM)

  const chosenStressLevel = useTitleBarSelect('stressLevel') || 'standard';

  const urlForFetch = !activeDate
    ? `most_recently_stored_report_data_agg/${props.fundId}/cpl_risk`
    : `stored_report_data_agg/${props.fundId}/cpl_risk/${activeDate}`;

  const fundLiquidityData = useFetchData({
    url: urlForFetch,
    keyName: makeUniqueDataKey(props.fundId, 'lasdab', activeDate),
    makeFetch: true,
  });
  return (
    <GeneralComponentErrorShield
      dataObjects={[fundLiquidityData]}
      customErrorMessage="No Data Available."
    >
      <LasdabTable
        fundName={props.fundName}
        data={fundLiquidityData}
        stressLevel={chosenStressLevel}
      />
      <LasdabChart data={fundLiquidityData} stressLevel={chosenStressLevel} />
    </GeneralComponentErrorShield>
  );
};

export default LiquidityAdjustedSettlementDateAchievableBalance;
