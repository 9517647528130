import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import EquityTable from './components/table/table.component';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import ExposureGraphics from './components/ExposureGraphics.component';

export const assetClassUrls = {
  FETCH_EQUITY_DATA_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_EQUITY_DATA_SECOND_HALF: 'equity_specific_data',
  FETCH_FIXED_INCOME_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_FIXED_INCOM_SECOND_HALF: 'bond_specific_data',
};

const Equity: FC<FundInfoComponentProps> = (props) => {
  const selectedPage = useTitleBarSelect('selectVisualisation');
  const activeFund = props.fundId;
  const { positionDate } = props;
  const SelectedComponent =
    selectedPage === 'exposure' ? ExposureGraphics : EquityTable; // use the correct url depending on whether the date is set
  const mainUrl = `${
    positionDate
      ? 'stored_report_data_agg'
      : assetClassUrls.FETCH_EQUITY_DATA_FIRST_HALF
  }/${activeFund}/${assetClassUrls.FETCH_EQUITY_DATA_SECOND_HALF}`;
  const urlForUse = positionDate ? mainUrl + '/' + positionDate : mainUrl;

  const equityData = useFetchData({
    // urlForUse will be falsy if empty string, in which case we block the fetch
    keyName: `assetClassEquityData_${activeFund}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: urlForUse,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[equityData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      <SelectedComponent
        selectedEquityPage={selectedPage || 'table'}
        inputData={equityData}
      />
    </GeneralComponentErrorShield>
  );
};

export default Equity;
