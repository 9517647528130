import { FC } from 'react';
import FileExport from './FileExport.component';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { Grid } from '@mui/material';

const HistoricalDataExport: FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const { availableDates } = props;
  const riskMetrics = useFetchData({
    url: 'get_risk_metrics',
    keyName: 'risk_metrics',
    makeFetch: true,
  });
  return (
    <GeneralComponentErrorShield dataObjects={[riskMetrics]}>
      <Grid container spacing={2} style={{ padding: 8 }}>
        <FileExport
          availableDates={availableDates || []}
          riskMetrics={riskMetrics}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default HistoricalDataExport;
