import React, { ReactElement } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';

function PositionChanges(props: FundInfoComponentProps): ReactElement {
  return (
    <div>
      <h1>Im the position changes page</h1>
    </div>
  );
}

export default PositionChanges;
