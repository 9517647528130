import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import FirstLevelTable from './components/FirstLevelTable';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';

const ProspectusRestrictions: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const prospectusRestrictionsData = useFetchData({
    url: `fund_Risk_Compliance_data/${fundId}/${positionDate}`,
    keyName: `fund_Risk_Compliance_data_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[prospectusRestrictionsData]}
      customLoadingMessages={['Loading Prospectus Restrictions Data']}
    >
      <FirstLevelTable
        fundDetails={{ id: fundId, name: fundName }}
        activeFund={fundId}
        prospectusRestrictionsData={prospectusRestrictionsData}
        activeFundName={fundName}
      />
    </GeneralComponentErrorShield>
  );
};

export default ProspectusRestrictions;
