import { ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  formatMillions,
} from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Typography, useTheme } from '@mui/material';

interface LiquidityStressTestsGraphProps {
  data: any;
  numDays: number;
  marketMove: number;
}

interface assetDebtData {
  name: string;
  assetValue: number;
  debtValue: number;
}

function buildChartData(
  data: any,
  numDays: number,
  marketMove: number
): assetDebtData[] {
  if (!data.data.length) return [];
  if (!(numDays in data.data[0])) return [];
  if (!(marketMove in data.data[0][numDays])) return [];
  const timeseriesData = data.data[0][numDays][marketMove];
  const chartData: assetDebtData[] = [];

  for (const key in Object.keys(timeseriesData)) {
    chartData.push({
      name: key,
      assetValue: timeseriesData[key]['Asset Value'],
      debtValue: timeseriesData[key]['Debt Value'],
    });
  }

  return chartData;
}

function LiquidityStressTestBreakdownTimeSeries({
  data,
  numDays,
  marketMove,
}: // passValue,
// varFund,
LiquidityStressTestsGraphProps): ReactElement {
  const theme = useTheme();
  // function timeSeriesFormatter(data: any, lines: any) {
  //     // ? lines looks like this:
  //     /*
  //      * {dataKey, color, yAxisID}
  //      *
  //      * lineNames then will be
  //      */
  //     const lineNames = lines.map((line: any) => {
  //         return {
  //             name: line.dataKey,
  //             formatter: line.formatter,
  //         };
  //     });

  //     return data.map((row: any) => {
  //         const obj: any = {};

  //         obj.name = row[0];
  //         // the name value will always be what goes on the y axis

  //         lineNames.forEach((lineName: any, index: number) => {
  //             obj[lineName.name] = row[index];
  //         });

  //         return obj;
  //     });
  // }
  // const secondaryDataKey = varFund ? 'Value at Risk' : 'Commitment';

  const chartData = buildChartData(data, numDays, marketMove);

  let minValue = chartData[0].assetValue;
  let maxValue = chartData[0].assetValue;
  chartData.forEach((value: any) => {
    if (minValue > value.assetValue) minValue = value.assetValue;
    if (maxValue < value.assetValue) maxValue = value.assetValue;
  });
  if (maxValue < chartData[0].debtValue) maxValue = chartData[0].debtValue;
  if (minValue > chartData[0].debtValue) minValue = chartData[0].debtValue;
  return (
    <GridItem card xs={12}>
      <div style={{ padding: 8 }}>
        <Typography variant="h2">{'Asset / Debt values Timeseries'}</Typography>
        <GenericLineChart
          // referenceLineY={maxDrawdown ? maxDrawdown : undefined}
          // referenceLineYLabel={`Maximum Drawdown: ${percentageToTwoDecimalPlacesNoHundred(maxDrawdown)}`}
          title={'Asset / Debt values Timeseries'}
          width="100%"
          height={400}
          error={null}
          data={chartData}
          showDot={false}
          // backgroundColor={hexToRGBA(passValue, 0.3)}
          lines={[
            {
              dataKey: 'assetValue',
              color: mainColors.Pass,
              yAxisId: 'left',
            },
            {
              dataKey: 'debtValue',
              color: mainColors.Fail,
              yAxisId: 'left',
            },
          ]}
          yAxisLabel={{
            value: 'Asset / Debt Value',
            angle: -90,
            style: { textAnchor: 'middle', fill: theme.palette.grey[500] },

            position: 'left',
          }}
          yAxisDomain={[
            minValue - Math.abs(minValue) * 0.1,
            maxValue + Math.abs(maxValue) * 0.1,
          ]}
          yAxisTickFormatter={(val: any) => {
            return formatMillions(val);
          }}
          xAxisLabel={{
            value: 'Month',
            position: 'bottom',
            style: {
              fill: theme.palette.grey[500],
            },
          }}
          // tooltipFormatter={(value: any, name: any) => `${value}%`}
          // additionalYAxis={[
          //     {
          //         // ticks: secondaryTickValues,
          //         leftDataKey: 'globalExposure',
          //         yAxisId: 'right',
          //         orientation: 'right',
          //         domain: [
          //             (dataMin: any) => {
          //                 return dataMin - dataMin * 0.1;
          //             },
          //             (dataMax: any) => dataMax + dataMax * 0.1,
          //         ],
          //         label: {
          //             value: secondaryDataKey,
          //             angle: -90,
          //             position: 'right',
          //             style: {
          //                 textAnchor: 'middle',
          //                 fill: theme.palette.grey[500],
          //             },
          //         },
          //         tickFormatter: (val: any) => {
          //             return `${(val * 100).toFixed(1)}%`;
          //         },
          //     },
          // ]}
          margin={{ top: 0, left: 10, right: 40, bottom: 50 }}
          tooltipFormatter={(value: any, name: any) =>
            addCommasToNumbersAndRound(value)
          }
        />
      </div>
    </GridItem>
  );
}

export default LiquidityStressTestBreakdownTimeSeries;
