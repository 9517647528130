import React, { FC } from 'react';
import { useWindowSize } from 'react-use';

import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface fxRiskTableProps {
  data: any;
  title: string;
}

interface IfxRiskData {
  // Define Column types
  forex: number;
  total: number;
  portfolioName: string;
  portfolioNumber: number;
}

function buildFxRiskTableData(data: any, title: string): IfxRiskData[] {
  const tableData: IfxRiskData[] = [];
  const FxRiskData = data.data[0];
  const nav = FxRiskData.nav;

  for (let index = 0; index < FxRiskData.fx_var_data.length; index++) {
    tableData.push({
      portfolioNumber: index + 1,
      portfolioName: FxRiskData.fx_var_data[index][0],
      forex: FxRiskData.fx_var_data[index][1] / nav,
      total: FxRiskData.pf_var_data[index][1] / nav,
    });
  }

  return tableData;
}

const detailColumns: CustomColumn<IfxRiskData>[] = [
  {
    title: '#',
    field: 'portfolioNumber',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Portfolio',
    field: 'portfolioName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Forex',
    field: 'forex',
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.forex),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Total',
    field: 'total',
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.total),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
];

const FxRiskTable: FC<fxRiskTableProps> = (props) => {
  // const theme = useTheme();
  const { data, title } = props;
  const tableData = buildFxRiskTableData(data, title);
  const { height, width } = useWindowSize();

  return (
    <GridItem xs={12} card style={{ padding: 8 }}>
      <CustomTable<IfxRiskData>
        title={title}
        columns={detailColumns}
        showToolbar
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default FxRiskTable;
