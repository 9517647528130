import React from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import {
  ShareClassHedgeTableData,
  generateShareClassHedgeData,
  generateShareClassTableColumns,
} from '../shareClassHedge/ShareClassHedgeData.data';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

export function getFundName(data: any): any {
  if (!data) {
    return [];
  }
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  return data.data[0].external_fund_name;
}

export function getNavDate(data: any): any {
  if (!data) {
    return [];
  }
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  return data.data[0].selected_position_date;
}

const ShareClassTable = ({ data }: { data: DataObject }) => {
  const generatedData = generateShareClassHedgeData(data.data[0]);
  const fundName = getFundName(data);
  const navDate = getNavDate(data);
  return (
    <GridItem xs={12} card>
      <CustomTable<ShareClassHedgeTableData>
        title={fundName + ' - NAV Date: ' + navDate}
        data={generatedData}
        columns={generateShareClassTableColumns()}
        options={{ paging: false, exportButton: true }}
        showToolbar
      />
    </GridItem>
  );
};

const QuaysideShareClassHedge: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const fxFundId = useTitleBarSelect('fxFunds');
  const navStress = useTitleBarSelect('navStress');
  const fxStress = useTitleBarSelect('fxStress');

  const shareClassHedgeData = useFetchData({
    url: `/msc_share_class_fx_hedge_monitor_rt_agg/${fxFundId}/${navStress}/${fxStress}`,
    keyName: `share_class_hedge_data_${fxFundId}_${navStress}_${fxStress}`,
    makeFetch:
      fxFundId !== undefined &&
      navStress !== undefined &&
      fxStress !== undefined
        ? true
        : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[shareClassHedgeData]}
      customLoadingMessages={[
        'Loading Share Class Hedge Data',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <ShareClassTable data={shareClassHedgeData} />
    </GeneralComponentErrorShield>
  );
};

export default QuaysideShareClassHedge;
