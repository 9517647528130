import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { openingRouteSelector } from '../redux/pages/selectors';

const LoggedOutRoute = (props: RouteProps) => {
  const history = useHistory();
  const openingRoute = useSelector(openingRouteSelector);
  const isAuthenticated = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (isAuthenticated) {
      if (openingRoute) {
        history.push('/' + openingRoute);
      } else {
        history.push('/login');
      }
    }
  }, [history, isAuthenticated]);

  return <Route {...props} />;
};
export default LoggedOutRoute;
