import { FC, useEffect, useState } from 'react';
import { FundInfoComponentProps } from '../../general/GeneralFundInfoPage';
import useFetchData from '../../../hooks/useFetchData';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { FundType } from '../../../types/server-data/ClientConfig';
import useTitleBarSelect from '../../../hooks/useTitleBarSelect';
import { DataObject } from '../../../types/redux/data/dataTypes';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../redux/pages/actions';
import { Select } from '../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../types/redux/pages/pageTypes';
import { clientNameSelector } from '../../../redux/auth/selectors';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import GridItem from '../../layout/GridComponents/GridItem';
import AggregateExposureTable from './exposureComponents/AggregateExposureTable';
import AssetGrossExposure from './exposureComponents/AssetGrossExposure';
import AssetClassGrossExposureChart from './exposureComponents/AssetClassGrossExposure';
import AssetCurrencyExposure from './exposureComponents/AssetCurrencyExposure';
import PositionsTable from './exposureComponents/positionsTable/PositionsTable.component';
import CountryCodeGrossExposure from './exposureComponents/CountryCodeGrossExposure';
import AssetCountryCodeExposure from './exposureComponents/AssetCountryCodeExposure';
import AmlLevelGrossExposure from './exposureComponents/AmlLevelGrossExposure';
import AssetAmlLevelExposure from './exposureComponents/AssetAmlLevelExposure';
import { Grid } from '@mui/material';
import { PresetExposureType } from './exposureComponents/generalTable/types/column.types';
import GeneralPositionsTable from './exposureComponents/generalTable/GeneralPositionsTable';

export interface ExposureFundProps {
  fundType: FundType;
  fundId: string;
  fundName: string;
  exposureData: DataObject;
}

const standardViewOptions = [
  {
    label: 'General Exposure',
    value: 'generalExposure',
  },
  {
    label: 'Country Exposure',
    value: 'countryExposure',
  },
];
const adepaViewOptions = [
  {
    label: 'General Exposure',
    value: 'generalExposure',
  },
  {
    label: 'Country Exposure',
    value: 'countryExposure',
  },
  {
    label: 'AML Exposure',
    value: 'amlExposure',
  },
];

function getViewOptions(fundId: string, clientName: string) {
  if (clientName === 'mersenne') {
    return adepaViewOptions;
    // I know this is not ideal but the aml exposure option is required for a demo so needs to be done for now.
    // IN future this can be moved to the config file to configure differnt options for different clients. DC - 2023-11-08
  } else if (
    fundId.startsWith('adepa') ||
    fundId.startsWith('gemini') ||
    fundId.startsWith('ifp')
  ) {
    return adepaViewOptions;
  } else {
    return standardViewOptions;
  }
}

const ExposureFund: FC<FundInfoComponentProps> = ({
  positionDate,
  fundId,
  section,
  fundName,
}) => {
  // Add Select for choosing the view
  const [viewOptions, setViewOptions] = useState<any>(null);

  const fundTypes = useSelector(createSectionByIdSelector(section))?.fundTypes;

  // this is problematic, we need to combine the routes to not have aif and standard gem rotues separated,
  // there have been so many issues resrtucturing that for now this hack will have to do to progress the project, Tom Walsh [2023-05-17]
  const urlPrefix = ['AIF', 'AIFMD', 'AIF_PE'].some((r) =>
    fundTypes?.includes(r)
  )
    ? 'aif_gem_data'
    : 'fund_gem_data';

  const exposureData = useFetchData({
    url: `${urlPrefix}/${fundId}/${positionDate}`,
    keyName: `${fundId}_${urlPrefix}_exposure_data_${positionDate}`,
    makeFetch: fundTypes && positionDate ? true : false,
  });

  // console.log('exposureData', exposureData);

  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const dispatch = useDispatch();
  useEffect(() => {
    // check if the view options need to change.
    const updatedViewOptions = getViewOptions(fundId, clientName);
    // If the options haven't changes then dont do anything
    if (updatedViewOptions === viewOptions) return;
    dispatch(toggleTitleBarLoading(true));
    if (exposureData && !exposureData.isFetching) {
      // Reset view options first
      setViewOptions(null);
      setViewOptions(updatedViewOptions);
    }
    return;
  }, [exposureData]);

  useEffect(() => {
    if (viewOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select View',
          titleBarKey: 'viewSelect',
          values: viewOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: viewOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [viewOptions, positionDate]);

  // Type safe the string
  const viewSelected = useTitleBarSelect('viewSelect') as PresetExposureType;

  return (
    <GeneralComponentErrorShield dataObjects={[exposureData]}>
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem card xs={4} cardStyle={{ height: '100%' }}>
          <AggregateExposureTable
            fundId={fundId}
            fundName={fundName}
            dataForRender={exposureData}
          />
        </GridItem>
        <GridItem
          xs={4}
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <AssetGrossExposure
            fundId={fundId}
            fundName={fundName}
            dataForRender={exposureData}
          />

          {/* ------------------------------ */}
          {/* display based on view selected */}
          {viewSelected === 'generalExposure' ? (
            <AssetClassGrossExposureChart dataForRender={exposureData} />
          ) : null}
          {viewSelected === 'countryExposure' ? (
            <CountryCodeGrossExposure dataForRender={exposureData} />
          ) : null}
          {viewSelected === 'amlExposure' ? (
            <AmlLevelGrossExposure dataForRender={exposureData} />
          ) : null}
          {/* ------------------------------ */}
        </GridItem>
        <GridItem xs={4} card cardStyle={{ height: '100%' }}>
          {/* ------------------------------ */}
          {/* display based on view selected */}
          {viewSelected === 'generalExposure' ? (
            <AssetCurrencyExposure
              fundId={fundId}
              fundName={fundName}
              dataForRender={exposureData}
            />
          ) : null}
          {viewSelected === 'countryExposure' ? (
            <AssetCountryCodeExposure
              fundId={fundId}
              fundName={fundName}
              dataForRender={exposureData}
            />
          ) : null}
          {viewSelected === 'amlExposure' ? (
            <AssetAmlLevelExposure
              fundId={fundId}
              fundName={fundName}
              dataForRender={exposureData}
            />
          ) : null}
          {/* ------------------------------ */}
        </GridItem>
      </Grid>
      <Grid container spacing={2} style={{ padding: 8 }}>
        {/* <PositionsTable
          dataForRender={exposureData}
          getRowCanExpand={() => true}
          fundId={fundId}
          fundName={fundName}
          exposureType={viewSelected ?? 'generalExposure'}
          pdfExportGroupName="exposure_page"
          pdfExportGroupOrder={5}
        /> */}
        <GeneralPositionsTable
          dataForRender={exposureData}
          getRowCanExpand={() => true}
          fundId={fundId}
          fundName={fundName}
          exposureType={viewSelected ?? 'generalExposure'}
          pdfExportGroupName="exposure_page"
          pdfExportGroupOrder={5}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default ExposureFund;
