import React, { useEffect, useState } from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import makeStyles from '@mui/styles/makeStyles';
import PasswordSettings from './subComponents/PasswordSettings.component';
import LoginSettings from './subComponents/LoginSettings.component';
import InvitationSettings from './subComponents/InvitationSettings.component';
import requestClient from '../../../../utilities/requestClient';
import TokenService from '../../../services/token-service';
import { greys, mainColors } from '../../../../styling/theme';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';

const useStyles = makeStyles(() => ({
  pageRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '1rem',
  },
}));

export interface UserAuthPreferencesData {
  attribute_type: string;
  user_or_client_id: string;
  attribute_name: string;
  attribute_value: number;
  attribute_last_updating_datetime: string;
}

export const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

// function to ensure values are at least 1
export const ensureMinimumValueOfOne = (value: number): number => {
  if (value < 1) {
    return 1;
  } else {
    return value;
  }
};

const twoFactorReadyClients = () => {
  return [
    'mersenne',
    'pure',
    'gemini'
  ];
};

const SecuritySettings: React.FC<SettingsComponentProps> = () => {
  const classes = useStyles();

  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const client = requestClient();

  const [userAuthPreferences, setUserAuthPreferences] = useState<
    UserAuthPreferencesData[]
  >([]);

  const getUserAuthPreferences = () => {
    client
      .get('raptor/settings/auth_preference/attributes', {
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          setUserAuthPreferences(response.data);
        }
      });
  };
  useEffect(() => {
    getUserAuthPreferences();
  }, []);

  return (
    <div className={classes.pageRoot}>
      <PasswordSettings
        password_update_frequency_days={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'password_update_frequency_days'
          ) as UserAuthPreferencesData
        }
        reloadSettings={getUserAuthPreferences}
      />
      <LoginSettings
        maximum_failed_login_attempts={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'maximum_failed_login_attempts'
          ) as UserAuthPreferencesData
        }
        account_frozen_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'account_frozen_minutes'
          ) as UserAuthPreferencesData
        }
        two_factor_auth={
          twoFactorReadyClients().includes(clientName)
            ? (userAuthPreferences.find(
                (preference: UserAuthPreferencesData) =>
                  preference.attribute_name === '2fa_user_email'
              ) as UserAuthPreferencesData)
            : undefined
        }
        reloadSettings={getUserAuthPreferences}
      />
      <InvitationSettings
        account_invitation_link_valid_period_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name ===
              'account_invitation_link_valid_period_minutes'
          ) as UserAuthPreferencesData
        }
        forget_passwd_invitation_link_valid_period_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name ===
              'forget_passwd_invitation_link_valid_period_minutes'
          ) as UserAuthPreferencesData
        }
        reloadSettings={getUserAuthPreferences}
      />
    </div>
  );
};

export default SecuritySettings;
