import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import LawFirstLevelTable from './lawComponents/LawFirstLevelTable';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import { updateTitleBarSelectValue } from '../../../../redux/pages/actions';
import { useDispatch } from 'react-redux';

const RiaifLaw: FC<FundInfoComponentProps> = (props) => {
  const { positionDate } = props;

  const riaifComplianceChecklist = useFetchData({
    url: 'compliance_checklist/RIAIF',
    keyName: 'riaif_compliance_checklist',
    makeFetch: true,
  });
  const basicDataAgg = useFetchData({
    url: formatFundUrl('basic_data_agg', props.fundId, positionDate),
    keyName: makeUniqueDataKey(props.fundId, 'basicDataAgg', positionDate), // this is the same as var
    makeFetch: true,
  });

  const riaifFundData = useFetchData({
    url: formatFundUrl('fund_RIAIF_data', props.fundId, positionDate),
    keyName: makeUniqueDataKey(props.fundId, 'riaifFundData', positionDate),
    makeFetch: true,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateTitleBarSelectValue('dataDisplay', 'current'));
  }, []);
  return riaifComplianceChecklist && basicDataAgg && riaifFundData ? (
    <LawFirstLevelTable
      dataSource={{
        complianceChecklist: riaifComplianceChecklist,
        basicDataAgg,
        riaifFundData,
      }}
      activeFundName={props.fundName}
      activeFund={props.fundId}
    />
  ) : null;
};

export default RiaifLaw;
