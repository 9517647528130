import React, { FC } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import ExposureGraphics from './components/ExposureGraphics.component';
import EquityTable from './components/table/table.component';
import useFetchData from '../../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';

export const assetClassUrls = {
  FETCH_EQUITY_DATA_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_EQUITY_DATA_SECOND_HALF: 'equity_specific_data',
  FETCH_FIXED_INCOME_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_FIXED_INCOM_SECOND_HALF: 'bond_specific_data',
};

const MancoEquity: FC<GeneralOverviewPageRenderedComponentProps> = (props) => {
  const selectedPage = useTitleBarSelect('selectVisualisation');
  const { positionDate } = props;
  const SelectedComponent =
    selectedPage === 'exposure' ? ExposureGraphics : EquityTable; // use the correct url depending on whether the date is set

  const equityData = useFetchData({
    // urlForUse will be falsy if empty string, in which case we block the fetch
    keyName: `manco_asset_class_equity_data_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: `manco_level_asset_class_data/equity${
      positionDate ? '/' + positionDate : ''
    }`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[equityData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      <SelectedComponent
        selectedEquityPage={selectedPage || 'table'}
        inputData={equityData}
      />
    </GeneralComponentErrorShield>
  );
};

export default MancoEquity;
