import React, { ReactElement, useState } from 'react';
import { useAdditionalTitleBarSelect } from '../../../../hooks/useAdditionalTitleBarComponents';
import { useUncachedDataFetch } from '../../../../hooks/useUncachedDataFetch';

import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';

import FileCard from '../Library/FileCard';
import { BASE_URL } from '../../../../utilities/requestClient';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { Typography } from '@mui/material';

function KiidRender({
  selectData,
  fundId,
}: {
  selectData: any[];
  fundId: string;
}): ReactElement | null {
  const [dateFiles, setdateFiles] = useState([]);
  const options = selectData.map((option) => ({
    label: option[1],
    value: option[0],
  }));

  useAdditionalTitleBarSelect({
    displayOnOverviewPage: false,
    titleBarKey: 'shareClass',
    placeholderValue: 'Please select a share class',
    titleBarTitle: 'Select Share Class',
    values: options,
    aValueIsAlwaysSelected: false,
    currentValue: 'none_selected',
  });

  const shareClassSelect = useTitleBarSelect('shareClass');
  const selectedShareClass = options.find(
    (option) => option.value === shareClassSelect
  );
  const shareClassValue = selectedShareClass?.value;

  const fileDateValues = useUncachedDataFetch(
    shareClassSelect && shareClassSelect !== 'none_selected'
      ? `kiid_files/list/${fundId}/${shareClassSelect}`
      : null
  );

  return fileDateValues.data.length > 0 ? (
    <>
      {fileDateValues.data.map((el) => (
        <FileCard
          predefinedUrl={`${BASE_URL}/kiid_files/get/${fundId}/${shareClassValue}/${el}`}
          key={el}
          title={el}
          prefix={''}
        />
      ))}
    </>
  ) : (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h2">Select a share class</Typography>
    </DisplayAreaCenteredWrapper>
  );
}

function Kiid(props: FundInfoComponentProps): ReactElement {
  const selectData = useUncachedDataFetch(
    `coutts_isin_data_dictionary/${props.fundId}`
  );

  return (
    <GeneralComponentErrorShield dataObjects={[selectData]}>
      <KiidRender fundId={props.fundId} selectData={selectData.data} />
    </GeneralComponentErrorShield>
  );
}

export default Kiid;
