import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  createSectionByIdSelector,
  createSpecificFundNameAndIdSelectorByFundType,
} from '../../../../redux/pages/selectors';
import { Status } from '../../../../types/redux/data/dataTypes';
import RaptorLoading from '../../../feedback/RaptorLoading';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  StressTestsData,
  buildStressColumns,
} from '../stressTests/StressTestsTable';

interface fxRiskStressScenarioTableProps {
  data: any;
  section: string;
  pageId: string;
  fundId: string;
  fundName: string;
  positionDate?: string;
}

export function buildFxStressTestData(inputData: any, inputRestrictions: any) {
  if (!inputData || !inputRestrictions) return [];
  try {
    const mainData = inputData.data[0].stress_test_data;
    const nav = inputData.data[0].nav;
    const restrictions = inputRestrictions;

    const returnArr: StressTestsData[] = [];
    returnArr.push({
      headerRow: true,
      stressScenarioType: 'Risk Sector Stress',
      name: null,
      pL: null,
      pLstatus: null,
      exAnteVolatility: null,
      exAnteVar: null,
      numSds: null,
      numVar: null,
      exPostVolatility: null,
      exPostVar: null,
    });
    for (let index = 0; index < mainData.length; index++) {
      const name = mainData[index][0];
      const dataValuesForOtherColumns = mainData[index][1];
      const pLvalue = dataValuesForOtherColumns[0] / nav;
      returnArr.push({
        headerRow: false,
        stressScenarioType: null,
        name,
        pL: pLvalue,
        pLstatus:
          pLvalue < 0 && Math.abs(pLvalue) >= restrictions[name]
            ? Status.Alert
            : Status.Pass, // come back to this
        exAnteVolatility: dataValuesForOtherColumns[1] / nav,
        exAnteVar: dataValuesForOtherColumns[2] / nav,
        numSds: dataValuesForOtherColumns[3],
        numVar: dataValuesForOtherColumns[4],
        exPostVolatility: dataValuesForOtherColumns[5] / nav,
        exPostVar: dataValuesForOtherColumns[6] / nav,
      });
    }

    return returnArr;
  } catch (err) {
    console.error('Problem building stress data: ', err);
  }

  return [];
}

const FxRiskStressScenarioTable: FC<fxRiskStressScenarioTableProps> = (
  props
) => {
  const { data, fundId, positionDate } = props;
  const sectionDetailsSelector = createSectionByIdSelector(props.section);
  const sectionDetails = useSelector(sectionDetailsSelector);
  const fundDetailsSelector = createSpecificFundNameAndIdSelectorByFundType(
    sectionDetails?.fundTypes || 'UCITS',
    fundId,
    sectionDetails?.rsResources || 'RISK,LIQUIDITY'
  );
  const fundDetails = useSelector(fundDetailsSelector);

  const stressTestData = data.data[0].stress_test_data;

  const stressTestRestrictions = data.data[0].stress_test_restrictions;

  const columns = buildStressColumns();

  const dataArrived = stressTestData && stressTestRestrictions;
  const finishedLoading =
    dataArrived &&
    !stressTestData.isFetching &&
    !stressTestRestrictions.isFetching;
  return dataArrived && fundDetails ? (
    finishedLoading ? (
      <GridItem card xs={12}>
        <CustomTable<StressTestsData>
          pdfNoClearFirstRow
          options={{
            paging: false,
            exportButton: true,
          }}
          showToolbar={true}
          data={buildFxStressTestData(data, stressTestRestrictions)}
          toolbarComponents={{
            toolbarTitle: fundDetails.name,
          }}
          columns={columns}
          csvFields={[
            'stressScenarioType',
            'name',
            'pL',
            'pLstatus',
            'exAnteVolatility',
            'exAnteVar',
            'numSds',
            'numVar',
            'exPostVolatility',
            'exPostVar',
          ]}
        />
      </GridItem>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};

export default FxRiskStressScenarioTable;
