import React, { FC, useEffect, useState } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import RaptorLoading from '../../../feedback/RaptorLoading';
import ReportsTable from './table/component';
import { AifmdReportType } from '../overview/table/components/ReportsLinkButton/reportsLinkButton.component';
import { formatFundUrl } from '../../../../utilities/urlBuilder';
import { useLocation } from 'react-router-dom';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { useDispatch } from 'react-redux';
import { updateTitleBarSelectValue } from '../../../../redux/pages/actions';

export const REPORTS_OPTIONS = [
  'Market Limit Monitor',
  'Counterparty Limit Monitor',
  'Credit Limit Monitor',
  'Liquidity Limit Monitor',
];

const AifmdReports: FC<FundInfoComponentProps> = (props) => {
  const activeFund = props.fundId;
  const { positionDate } = props;
  const selectedReport = useTitleBarSelect('reportType');
  const [localStorageReportValue, setLocalStorageReportValue] = useState<
    string | null
  >(null);

  const location = useLocation<{ reportType: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    const reportType = localStorage.getItem('AIF_REPORT_TYPE');
    if (reportType) {
      setLocalStorageReportValue(reportType);
    }
  }, [location.state]);
  useEffect(() => {
    if (selectedReport && localStorageReportValue) {
      dispatch(
        updateTitleBarSelectValue('reportType', localStorageReportValue)
      );
      setLocalStorageReportValue(null);
    }
  }, [selectedReport]);

  const reportsData = useFetchData({
    keyName: `aifmd_reports_${
      positionDate || formatDateForCheckingState(new Date())
    }_${activeFund}`,
    url: formatFundUrl('aifmd_aggregate_risk_report', activeFund, positionDate),
    makeFetch: true,
  });

  return reportsData ? (
    !reportsData.isFetching ? (
      <GridItem card xs={12}>
        <ReportsTable
          inputData={reportsData}
          reportType={
            (selectedReport as unknown as AifmdReportType) || 'market'
          }
        />
      </GridItem>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};

export default AifmdReports;
