import React from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useTitleBarInput from '../../../../hooks/useTitleBarInput';
import PositionsOversightTable from './subComponents/PositionsOversightTable.component';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Grid } from '@mui/material';

const PositionOversight: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const lookbackDays = useTitleBarInput('lookbackDays');

  const mancoPositionComparisonData = useFetchData({
    url: `/manco_position_comparison/${lookbackDays}/${
      props.positionDate ? props.positionDate : ''
    }`,
    keyName: `manco_position_comparison_${lookbackDays}_${
      props.positionDate ? props.positionDate : ''
    }`,
    makeFetch: lookbackDays !== undefined,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[mancoPositionComparisonData]}
      customLoadingMessages={[
        'Loading Positions...',
        'This May Take A Few Moments',
      ]}
      customErrorMessage="No Positions Found"
    >
      {mancoPositionComparisonData &&
      mancoPositionComparisonData.data &&
      mancoPositionComparisonData.data[0] &&
      mancoPositionComparisonData.data[0].length ? (
        <Grid container spacing={2} style={{ padding: 8 }}>
          <PositionsOversightTable
            originalData={mancoPositionComparisonData}
            positionDate={props.positionDate ? props.positionDate : ''}
          />
        </Grid>
      ) : (
        <NoDataMessage message="No Positions Found" />
      )}
    </GeneralComponentErrorShield>
  );
};

export default PositionOversight;
